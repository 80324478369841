import { MutationUpdaterFn } from "@apollo/client";
import { TEAMS_QUERY } from "../gql/teams";
import { updateCache } from "../utils/updateCache";
import { DeleteTeam, Teams } from "../__generated__/types";

export const deleteTeamUpdate: MutationUpdaterFn<DeleteTeam> = (
  cache,
  result
) => {
  updateCache<Teams, unknown, DeleteTeam>({
    cache,
    result,
    cachedQuery: { query: TEAMS_QUERY },
    update: ({ teams }, { deleteTeam: { id } }) => ({
      teams: teams.filter((cached) => cached.id !== id),
    }),
  });

  if (result.data?.deleteTeam) {
    const { id, __typename } = result.data.deleteTeam;
    cache.evict({ id: `${__typename}:${id}` });
    cache.gc();
  }
};
