import * as React from "react";
import * as ReactDOM from "react-dom";
import { FocusStyleManager } from "@blueprintjs/core";
import { ApolloProvider } from "@apollo/client";
import { LoginProvider } from "./context/loginContext";
import { client } from "./apollo";
import Root from "./components/Root";
import { BrowserRouter } from "react-router-dom";
import AssureLogin from "./components/AssureLogin";
import "./stylesheets/global.scss";

// BlueprintJS configuration
FocusStyleManager.onlyShowFocusOnTabs();

ReactDOM.render(
  <ApolloProvider client={client}>
    <LoginProvider>
      <BrowserRouter>
        <AssureLogin>
          <Root />
        </AssureLogin>
      </BrowserRouter>
    </LoginProvider>
  </ApolloProvider>,
  document.getElementById("root") as HTMLElement
);
