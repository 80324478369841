import React from "react";
import { Spinner } from "@blueprintjs/core";
import s from "../stylesheets/Loading.module.scss";

const Loading: React.FC = () => (
  <div className={s.root}>
    <Spinner size={30} intent="primary" />
  </div>
);

export default Loading;
