import React, { FC } from "react";
import { useField } from "formik";
import { TextArea, FormGroup, Intent } from "@blueprintjs/core";

interface Props {
  name: string;
  label: string;
  helperText: string;
}

const TextAreaField: FC<
  Props & React.TextareaHTMLAttributes<HTMLTextAreaElement>
> = ({ name, label, helperText, ...props }) => {
  const [{ value, onChange, onBlur }, { touched, error }] = useField(name);
  const errorMsg = touched && error;
  return (
    <FormGroup
      label={label}
      helperText={errorMsg ? errorMsg : helperText}
      intent={errorMsg ? Intent.DANGER : Intent.NONE}
    >
      <TextArea
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        rows={3}
        fill
        large
        {...props}
      />
    </FormGroup>
  );
};

export default TextAreaField;
