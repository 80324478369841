import { gql } from "@apollo/client";

export const TERRITORY_ATTRIBUTES = gql`
  fragment TerritoryAttributes on Territory {
    id
    iso3
    nameEn
    namePl
    languageEn
    languagePl
    mailchimpID
  }
`;

export const TERRITORY_NAMES = gql`
  fragment TerritoryNames on Territory {
    id
    nameEn
    namePl
  }
`;

export const TERRITORY_QUERY = gql`
  query Territory($id: ID!) {
    territory(id: $id) {
      ...TerritoryAttributes
    }
  }
  ${TERRITORY_ATTRIBUTES}
`;

export const TERRITORIES_QUERY = gql`
  query Territories {
    territories {
      ...TerritoryAttributes
    }
  }
  ${TERRITORY_ATTRIBUTES}
`;

export const CREATE_TERRITORY_MUTATION = gql`
  mutation CreateTerritory($data: TerritoryInput!) {
    createTerritory(data: $data) {
      ...TerritoryAttributes
    }
  }
  ${TERRITORY_ATTRIBUTES}
`;

export const DELETE_TERRITORY_MUTATION = gql`
  mutation DeleteTerritory($id: ID!) {
    deleteTerritory(id: $id) {
      id
    }
  }
`;

export const UPDATE_TERRITORY_MUTATION = gql`
  mutation UpdateTerritory($id: ID!, $data: TerritoryInput!) {
    updateTerritory(id: $id, data: $data) {
      ...TerritoryAttributes
    }
  }
  ${TERRITORY_ATTRIBUTES}
`;

export const MAILCHIMP_TERRITORIES_QUERY = gql`
  query MailchimpTerritories {
    mailchimpTerritories {
      id
      name
    }
  }
`;
