import React, { FC, memo } from "react";
import { Card, H3 } from "@blueprintjs/core";
import { History } from "history";
import { MyClients_myClients } from "../__generated__/types";
import daysSinceNow from "../utils/daysSinceNow";
import DetailTag from "./DetailTag";
import DetailTagRow from "./DetailTagRow";
import s from "../stylesheets/ClientCard.module.scss";

interface Props {
  client: MyClients_myClients;
  history: History;
}

const ClientCard: FC<Props> = ({ client, history }) => {
  function hasOutdatedCatalogs(client: MyClients_myClients): boolean {
    return client.catalogs.some((c) => daysSinceNow(c.dateAdded) > 365);
  }

  return (
    <Card
      elevation={2}
      key={client.id}
      className={s.root}
      interactive
      onClick={() => history.push(`/clients/${client.id}/edit`)}
    >
      <H3 className={s.name}>{client.name}</H3>
      <DetailTagRow>
        <DetailTag
          icon="document"
          intent={hasOutdatedCatalogs(client) ? "warning" : "none"}
          visible={client.catalogs.length > 0}
          content={client.catalogs.length.toString()}
        />
        <DetailTag
          intent="primary"
          visible={!client.published}
          content="Unpublished"
        />
        <DetailTag
          intent="primary"
          visible={!client.website}
          content="No website"
        />
      </DetailTagRow>
    </Card>
  );
};

export default memo(ClientCard);
