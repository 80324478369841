import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { CreateBook, CreateBookVariables } from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { CREATE_BOOK_MUATATION } from "../gql/books";
import BookForm from "./BookForm";
import FormView from "./FormView";
import { createBookUpdate } from "../updateFunctions/createBookUpdate";

const BookNew: FC<RouteComponentProps<
  {},
  {},
  { authorID?: string } | undefined
>> = ({ history, location: { state } }) => {
  const redirect = state?.authorID
    ? `/authors/${state.authorID}/edit`
    : "/authors";

  const createBook = useCustomMutation<CreateBook, CreateBookVariables>({
    mutation: CREATE_BOOK_MUATATION,
    successMsg: "Book created successfully",
    redirectCallback: () => history.push(redirect),
  });

  const initialValues: BookValues = {
    titleEn: "",
    titlePl: "",
    subtitleEn: "",
    subtitlePl: "",
    descriptionEn: "",
    descriptionPl: "",
    coverKey: "",
    publisher: "",
    pubYear: new Date().getFullYear(),
    published: true,
    authorIDs: state && state.authorID ? [state.authorID] : [],
    transLangIDs: [],
  };

  return (
    <FormView
      title="New Book"
      main={
        <BookForm
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await createBook({
                variables: { data: values },
                update: createBookUpdate,
              });
            } catch (error) {
              setSubmitting(false);
            }
          }}
          onBackClick={() => history.push(redirect)}
        />
      }
    />
  );
};

export default BookNew;
