import React, { FC } from "react";
import { Tag, Intent } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import s from "../stylesheets/DetailTag.module.scss";

interface Props {
  icon?: IconName;
  intent?: Intent;
  content: string;
  visible: boolean;
}

const DetailTag: FC<Props> = ({ icon, intent, content, visible }) => {
  return visible ? (
    <Tag large minimal icon={icon} intent={intent} className={s.root}>
      {content}
    </Tag>
  ) : null;
};

export default DetailTag;
