import { MutationUpdaterFn } from "@apollo/client";
import { LANGUAGES_QUERY } from "../gql/languages";
import { updateCache } from "../utils/updateCache";
import { DeleteLanguage, Languages } from "../__generated__/types";

export const deleteLanguageUpdate: MutationUpdaterFn<DeleteLanguage> = (
  cache,
  result
) => {
  updateCache<Languages, unknown, DeleteLanguage>({
    cache,
    result,
    cachedQuery: { query: LANGUAGES_QUERY },
    update: ({ languages }, { deleteLanguage: { id } }) => ({
      languages: languages.filter((cached) => cached.id !== id),
    }),
  });

  if (result.data?.deleteLanguage) {
    const { id, __typename } = result.data.deleteLanguage;
    cache.evict({ id: `${__typename}:${id}` });
    cache.gc();
  }
};
