import React, { FC } from "react";
import { useField } from "formik";
import { Switch } from "@blueprintjs/core";

interface Props {
  name: string;
  label: string;
}

const CheckboxField: FC<Props> = ({ label, ...props }) => {
  const [{ name, value, onChange, onBlur }] = useField(props);
  return (
    <Switch
      label={label}
      name={name}
      checked={value}
      onChange={onChange}
      onBlur={onBlur}
      alignIndicator="right"
      style={{ display: "inline-block" }}
      large
    />
  );
};

export default CheckboxField;
