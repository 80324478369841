import React, { FC } from "react";
import { useField } from "formik";
import { FormGroup, NumericInput, Intent } from "@blueprintjs/core";

interface Props {
  name: string;
  label: string;
  helperText: string;
  valueAs?: "string" | "number";
  formGroup?: boolean;
  min?: number;
  max?: number;
}

const NumericInputField: FC<
  Props & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  name,
  label,
  helperText,
  valueAs = "string",
  formGroup = true,
  min,
  max,
}) => {
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField(name);
  const errorMsg = touched && error;
  const intent = errorMsg ? Intent.DANGER : Intent.NONE;

  const numericInput = (
    <NumericInput
      name={name}
      onValueChange={(valueAsNumber, valueAsString) =>
        valueAs === "string" ? setValue(valueAsString) : setValue(valueAsNumber)
      }
      onBlur={onBlur}
      value={value}
      intent={intent}
      min={min}
      max={max}
    />
  );

  return formGroup ? (
    <FormGroup
      label={label}
      helperText={errorMsg ? errorMsg : helperText}
      labelFor={name}
      intent={intent}
    >
      {numericInput}
    </FormGroup>
  ) : (
    numericInput
  );
};

export default NumericInputField;
