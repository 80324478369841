import React, { FC } from "react";
import { useField } from "formik";
import {
  InputGroup,
  FormGroup,
  Intent,
  IInputGroupProps,
  HTMLInputProps,
} from "@blueprintjs/core";

interface Props {
  name: string;
  label: string;
  helperText: string;
  formGroup?: boolean;
}

const TextField: FC<Props & IInputGroupProps & HTMLInputProps> = ({
  name,
  label,
  helperText,
  formGroup = true,
  ...props
}) => {
  const [{ value, onChange, onBlur }, { touched, error }] = useField(name);
  const errorMsg = touched && error;
  const inputGroup = (
    <InputGroup
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      large
      {...props}
    />
  );
  if (formGroup) {
    return (
      <FormGroup
        label={label}
        helperText={errorMsg ? errorMsg : helperText}
        intent={errorMsg ? Intent.DANGER : Intent.NONE}
      >
        {inputGroup}
      </FormGroup>
    );
  }
  return inputGroup;
};

export default TextField;
