import { MutationUpdaterFn } from "@apollo/client";
import { CreateClient, MyClients } from "../__generated__/types";
import { updateCache } from "../utils/updateCache";
import { MYCLIENTS_QUERY } from "../gql/clients";

export const createClientUpdate: MutationUpdaterFn<CreateClient> = (
  cache,
  result
) => {
  updateCache<MyClients, {}, CreateClient>({
    cache,
    result,
    cachedQuery: { query: MYCLIENTS_QUERY },
    update: (
      { myClients },
      { createClient: { id, __typename, name, letters, published, website } }
    ) => ({
      myClients: myClients.concat([
        {
          id,
          __typename,
          letters,
          name,
          published,
          website,
          catalogs: [],
        },
      ]),
    }),
  });
};
