import React, { FC } from "react";
import {
  DeleteTerritory,
  DeleteTerritoryVariables,
  Territories_territories,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { DELETE_TERRITORY_MUTATION } from "../gql/territories";
import DeleteModal from "./DeleteModal";
import { deleteTerritoryUpdate } from "../updateFunctions/deleteTerritoryUpdate";

interface Props {
  redirectCallback?: () => void;
  deletedTerritory: Territories_territories | null;
  handleClose: () => void;
}

const DelTerritory: FC<Props> = ({
  deletedTerritory,
  handleClose,
  redirectCallback,
}) => {
  const deleteTerritory = useCustomMutation<
    DeleteTerritory,
    DeleteTerritoryVariables
  >({
    mutation: DELETE_TERRITORY_MUTATION,
    successMsg: "Territory deleted successfully",
    redirectCallback: redirectCallback ? redirectCallback : handleClose,
  });
  return deletedTerritory ? (
    <DeleteModal
      deletedItem={deletedTerritory}
      nameFn={(item) => item.nameEn}
      handleClose={handleClose}
      handleRedirect={redirectCallback}
      handleDelete={(id) =>
        deleteTerritory({
          variables: { id },
          update: deleteTerritoryUpdate,
        })
      }
    />
  ) : null;
};

export default DelTerritory;
