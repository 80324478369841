import React from "react";
import { History } from "history";
import { Card, H4 } from "@blueprintjs/core";
import { Users_users } from "../__generated__/types";
import s from "../stylesheets/UserCard.module.scss";
import DetailTagRow from "./DetailTagRow";
import DetailTag from "./DetailTag";

interface Props {
  user: Users_users;
  history: History;
}

const UserCard: React.FC<Props> = ({ user, history }) => {
  return (
    <Card
      elevation={2}
      className={s.card}
      interactive
      onClick={() => history.push(`/users/${user.id}/edit`)}
    >
      <H4>{user.profile.name}</H4>
      <DetailTagRow>
        <DetailTag
          icon="cog"
          intent="primary"
          visible={user.admin}
          content="Administrator"
        />
      </DetailTagRow>
    </Card>
  );
};

export default UserCard;
