import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { CreateTeam, CreateTeamVariables } from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { CREATE_TEAM_MUTATION } from "../gql/teams";
import TeamForm from "./TeamForm";
import FormView from "./FormView";
import { createTeamUpdate } from "../updateFunctions/createTeamUpdate";

const TeamNew: FC<RouteComponentProps> = ({ history: { push } }) => {
  const createTeam = useCustomMutation<CreateTeam, CreateTeamVariables>({
    mutation: CREATE_TEAM_MUTATION,
    successMsg: "Team created successfully",
    redirectCallback: () => push("/teams"),
  });

  const initialValues: TeamValues = {
    agentID: "",
    assistantIDs: [],
  };

  return (
    <FormView
      title="New Team"
      main={
        <TeamForm
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await createTeam({
                variables: { data: values },
                update: createTeamUpdate,
              });
            } catch (error) {
              setSubmitting(false);
            }
          }}
          onBackClick={() => push("/teams")}
        />
      }
    />
  );
};

export default TeamNew;
