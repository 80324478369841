import React, { FC, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import classNames from "classnames";
import { FormikHelpers, Formik, Form } from "formik";
import * as Yup from "yup";
import { Users_users } from "../__generated__/types";
import TextField from "./TextField";
import FormButtons from "./FormButtons";
import DeleteUser from "./DeleteUser";
import s from "../stylesheets/UserForm.module.scss";
import CheckboxField from "./CheckboxField";
import { LoginContext } from "../context/loginContext";

interface Props extends RouteComponentProps {
  initialValues: UserValues;
  onBackClick: () => void;
  onSubmit: (
    values: UserValues,
    formikHelpers: FormikHelpers<UserValues>
  ) => void;
  prevUser?: Users_users | null;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Email invalid").required("Required"),
  name: Yup.string(),
  admin: Yup.bool().required("Required"),
});

const UserForm: FC<Props> = ({
  history: { push },
  initialValues,
  onBackClick,
  onSubmit,
  prevUser,
}) => {
  const [delUser, setDelUser] = useState<Users_users | null>(null);
  const [{ user }] = React.useContext(LoginContext);

  return (
    <Formik<UserValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <div className={classNames(s.row, s.name)}>
            <TextField
              name="email"
              label="Email"
              helperText="User's email address"
            />
          </div>
          {!prevUser && (
            <div className={classNames(s.row, s.name)}>
              <TextField name="name" label="Name" helperText="User's name" />
            </div>
          )}
          <div className={classNames(s.row, s.name)}>
            <CheckboxField name="admin" label="Admin" />
          </div>
          <FormButtons
            onBackClick={onBackClick}
            onDeleteClick={prevUser ? () => setDelUser(prevUser) : undefined}
            submitDisabled={isSubmitting}
          />
          {prevUser && user?.me.id !== prevUser.id && (
            <DeleteUser
              deletedUser={delUser}
              handleClose={() => setDelUser(null)}
              redirectCallback={() => push(`/users`)}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(UserForm);
