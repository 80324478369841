import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import {
  UpdateCatalog,
  UpdateCatalogVariables,
  MyCatalog,
  MyCatalogVariables,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { UPDATE_CATALOG_MUTATION, MYCATALOG_QUERY } from "../gql/catalogs";
import CatalogForm from "./CatalogForm";
import ErrorPage from "./ErrorPage";
import FormView from "./FormView";
import { useCustomQuery } from "../hooks/useCustomQuery";
import Loading from "./Loading";
import { updateCatalogUpdate } from "../updateFunctions/updateCatalogUpdate";

interface RouteState {
  prevClientID: string;
}

type Props = RouteComponentProps<
  { catalogID: string },
  {},
  RouteState | undefined
>;

const CatalogEdit: FC<Props> = ({ match, location, history }) => {
  const prevClientID = location.state?.prevClientID;
  const redirectURL = prevClientID
    ? `/clients/${prevClientID}/edit`
    : "/clients";
  const updateCatalog = useCustomMutation<
    UpdateCatalog,
    UpdateCatalogVariables
  >({
    mutation: UPDATE_CATALOG_MUTATION,
    successMsg: "Catalog updated successfully",
    redirectCallback: () => history.push(redirectURL),
  });

  const { loading, data, errorCode } = useCustomQuery<
    MyCatalog,
    MyCatalogVariables
  >({
    query: MYCATALOG_QUERY,
    variables: { id: match.params.catalogID },
  });
  if (loading) {
    return <Loading />;
  }
  if (data && data.myCatalog) {
    const { myCatalog: catalog } = data;
    const initialValues: CatalogValues = {
      name: catalog.name,
      url: catalog.url || "",
      fileKey: catalog.fileKey || "",
      dateAdded: new Date(catalog.dateAdded),
      clientID: catalog.client.id,
    };
    return (
      <FormView
        title={catalog.name}
        main={
          <CatalogForm
            prevCatalog={catalog}
            prevClientID={catalog.client.id}
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                updateCatalog({
                  variables: { id: match.params.catalogID, data: values },
                  update: updateCatalogUpdate(catalog.client.id),
                });
              } catch (error) {
                setSubmitting(false);
              }
            }}
            onBackClick={() => history.push(redirectURL)}
          />
        }
      />
    );
  }
  return <ErrorPage code={errorCode || 500} />;
};

export default CatalogEdit;
