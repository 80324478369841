import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import {
  UpdateUser,
  UpdateUserVariables,
  User,
  UserVariables,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import UserForm from "./UserForm";
import ErrorPage from "./ErrorPage";
import FormView from "./FormView";
import { UPDATE_USER_MUTATION, USER_QUERY } from "../gql/users";
import { useCustomQuery } from "../hooks/useCustomQuery";
import Loading from "./Loading";

type Props = RouteComponentProps<{ userID: string }>;

const UserEdit: FC<Props> = ({
  match: {
    params: { userID },
  },
  history: { push },
}) => {
  const updateUser = useCustomMutation<UpdateUser, UpdateUserVariables>({
    mutation: UPDATE_USER_MUTATION,
    successMsg: "User updated successfully",
    redirectCallback: () => push("/users"),
  });

  const { loading, data, errorCode } = useCustomQuery<User, UserVariables>({
    query: USER_QUERY,
    variables: { id: userID },
  });

  if (loading) {
    return <Loading />;
  }

  if (data && data.user) {
    const { user } = data;
    const initialValues: UserValues = {
      email: user.email,
      admin: user.admin,
    };

    return (
      <FormView
        title={user.profile.name || user.email}
        main={
          <UserForm
            prevUser={user}
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await updateUser({
                  variables: { id: userID, data: values },
                });
              } catch (error) {
                setSubmitting(false);
              }
            }}
            onBackClick={() => push("/users")}
          />
        }
      />
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default UserEdit;
