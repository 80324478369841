import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import {
  CreateTerritory,
  CreateTerritoryVariables,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { CREATE_TERRITORY_MUTATION } from "../gql/territories";
import TerritoryForm from "./TerritoryForm";
import FormView from "./FormView";
import { createTerritoryUpdate } from "../updateFunctions/createTerritoryUpdate";

const TerritoryNew: FC<RouteComponentProps> = ({ history: { push } }) => {
  const createTerritory = useCustomMutation<
    CreateTerritory,
    CreateTerritoryVariables
  >({
    mutation: CREATE_TERRITORY_MUTATION,
    successMsg: "Territory created successfully",
    redirectCallback: () => push("/territories"),
  });

  const initialValues: TerritoryValues = {
    iso3: "",
    nameEn: "",
    namePl: "",
    languageEn: "",
    languagePl: "",
    mailchimpID: "",
  };

  return (
    <FormView
      title="New Territory"
      main={
        <TerritoryForm
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await createTerritory({
                variables: { data: values },
                update: createTerritoryUpdate,
              });
            } catch (error) {
              setSubmitting(false);
            }
          }}
          onBackClick={() => push("/territories")}
        />
      }
    />
  );
};

export default TerritoryNew;
