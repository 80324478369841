import React, { FC } from "react";
import { H3, Button, NonIdealState } from "@blueprintjs/core";
import { MyClient_myClient_catalogs } from "../__generated__/types";
import { History } from "history";
import { useDeleteListItem } from "../hooks/useDeleteListItem";
import CatalogCard from "./CatalogCard";
import s from "../stylesheets/CatalogSidebar.module.scss";
import DeleteCatalog from "./DeleteCatalog";

interface Props {
  catalogs: MyClient_myClient_catalogs[];
  clientID: string;
  history: History;
}

const CatalogSidebar: FC<Props> = ({ catalogs, clientID, history }) => {
  const { delItem, onDelClick, onClose } = useDeleteListItem<
    MyClient_myClient_catalogs
  >();

  const AddNewCatalogBtn = (
    <Button
      intent="primary"
      text="Add"
      icon="add"
      onClick={() => history.push("/catalogs/new", { clientID })}
    />
  );

  const sortedCatalogs = [...catalogs].sort((a, b) =>
    a.dateAdded < b.dateAdded ? 1 : a.dateAdded > b.dateAdded ? -1 : 0
  );

  return (
    <div className={s.root}>
      {catalogs.length === 0 ? (
        <NonIdealState
          icon="document"
          title="No catalogs"
          description="There are no catalogs associated with this client yet..."
          action={AddNewCatalogBtn}
        />
      ) : (
        <React.Fragment>
          <div className={s.header}>
            <H3 className={s.title}>Catalogs</H3>
            {AddNewCatalogBtn}
          </div>
          {sortedCatalogs.map((c) => (
            <CatalogCard
              key={c.id}
              catalog={c}
              clientID={clientID}
              deleteHandler={onDelClick}
              history={history}
            />
          ))}
          <DeleteCatalog
            clientID={clientID}
            deletedCatalog={delItem}
            handleClose={onClose}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default CatalogSidebar;
