import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import { AppToaster } from "../toaster";

export const processServerErrors = (error: ApolloError): void => {
  if (error.networkError !== null) {
    AppToaster.show({ message: "Network error", intent: "danger" });
  } else if (error.graphQLErrors.length > 0) {
    error.graphQLErrors.forEach((e: GraphQLError) =>
      AppToaster.show({ message: e.message, intent: "danger" })
    );
  }
};
