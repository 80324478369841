import React, { FC, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Formik, FormikHelpers, Form } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import {
  DeleteClient,
  DeleteClientVariables,
  MyClient_myClient,
  MyAgents,
  Territories,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { DELETE_CLIENT_MUTATION } from "../gql/clients";
import CheckboxField from "./CheckboxField";
import DeleteWithConfirm from "./DeleteWithConfirm";
import TextAreaField from "./TextAreaField";
import TextField from "./TextField";
import s from "../stylesheets/ClientForm.module.scss";
import { useCustomQuery } from "../hooks/useCustomQuery";
import Loading from "./Loading";
import ErrorPage from "./ErrorPage";
import MultiSelectField from "./MultiSelectField";
import SelectField from "./SelectField";
import FormButtons from "./FormButtons";
import { deleteClientUpdate } from "../updateFunctions/deleteClientUpdate";
import { MYAGENTS_QUERY } from "../gql/users";
import { TERRITORIES_QUERY } from "../gql/territories";

interface Props extends RouteComponentProps {
  initialValues: ClientValues;
  onSubmit: (
    values: ClientValues,
    formikHelpers: FormikHelpers<ClientValues>
  ) => void;
  prevClient?: MyClient_myClient | null;
}

const requiredMsg = "Required";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(requiredMsg),
  descriptionEn: Yup.string().required(requiredMsg),
  descriptionPl: Yup.string().required(requiredMsg),
  letters: Yup.string().max(3, "No more than 3 letters").required(requiredMsg),
  website: Yup.string().url("Must be a valid url").nullable(),
  published: Yup.bool().required(requiredMsg),
  agentID: Yup.number().integer().required(requiredMsg),
  territoryIDs: Yup.array()
    .of(Yup.number())
    .min(1, "At least one territory is required")
    .required(requiredMsg),
});

const ClientForm: FC<Props> = ({
  history,
  initialValues,
  onSubmit,
  prevClient,
}) => {
  const [delClient, setDelClient] = useState<MyClient_myClient | null>(null);

  const deleteClient = useCustomMutation<DeleteClient, DeleteClientVariables>({
    mutation: DELETE_CLIENT_MUTATION,
    successMsg: "Client deleted successfully",
    redirectCallback: () => history.push("/clients"),
  });

  const {
    loading: agentsLoading,
    errorCode: agentsErrorCode,
    data: agentsData,
  } = useCustomQuery<MyAgents>({ query: MYAGENTS_QUERY });
  const {
    loading: territoriesLoading,
    errorCode: territoriesErrorCode,
    data: territoriesData,
  } = useCustomQuery<Territories>({ query: TERRITORIES_QUERY });

  if (agentsLoading || territoriesLoading) {
    return <Loading />;
  }

  if (agentsData?.myAgents && territoriesData?.territories) {
    const { myAgents } = agentsData;
    const { territories } = territoriesData;

    return (
      <Formik<ClientValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <div className={classNames(s.name, s.row)}>
              <TextField
                name="name"
                label="Name"
                autoComplete="client-name"
                helperText="Client's name using standard capitalization rules"
              />
            </div>
            <div className={classNames(s.description, s.row)}>
              <TextAreaField
                name="descriptionEn"
                label="English Description"
                helperText="Description in English"
                spellCheck="true"
                lang="en"
                rows={5}
              />
            </div>
            <div className={classNames(s.description, s.row)}>
              <TextAreaField
                name="descriptionPl"
                label="Polish Description"
                helperText="Description in Polish"
                spellCheck="true"
                lang="pl"
                rows={5}
              />
            </div>
            <div className={classNames(s.letters, s.row)}>
              <TextField
                name="letters"
                label="Letters"
                helperText="Decorative typography"
                spellCheck="false"
              />
            </div>
            <div className={classNames(s.website, s.row)}>
              <TextField
                name="website"
                label="Website"
                helperText="Website url"
                spellCheck="false"
              />
            </div>
            <div className={s.row}>
              <SelectField
                name="agentID"
                label="Agent"
                helperText="Should be self-explanatory"
                items={[...myAgents]
                  .map((agent) => ({
                    id: agent.id,
                    display: agent.profile.name,
                  }))
                  .sort((a, b) =>
                    Intl.Collator().compare(a.display, b.display)
                  )}
              />
            </div>
            <div className={classNames(s.territories, s.row)}>
              <MultiSelectField
                name="territoryIDs"
                label="Territories"
                helperText="Select at least one territory"
                items={[...territories]
                  .map((territory) => ({
                    id: territory.id,
                    display: territory.nameEn,
                  }))
                  .sort((a, b) =>
                    Intl.Collator().compare(a.display, b.display)
                  )}
              />
            </div>
            <div className={s.row}>
              <CheckboxField name="published" label="Published" />
            </div>
            <FormButtons
              onBackClick={() => history.push("/clients")}
              onDeleteClick={
                prevClient ? () => setDelClient(prevClient) : undefined
              }
              submitDisabled={isSubmitting}
            />
            <DeleteWithConfirm<MyClient_myClient>
              entity={delClient}
              handleClose={() => setDelClient(null)}
              handleSubmit={() => {
                if (delClient !== null) {
                  deleteClient({
                    variables: { id: delClient.id },
                    update: deleteClientUpdate(delClient.catalogs),
                  });
                }
              }}
            />
          </Form>
        )}
      </Formik>
    );
  }
  return <ErrorPage code={agentsErrorCode || territoriesErrorCode || 500} />;
};

export default withRouter(ClientForm);
