import { gql } from "@apollo/client";

export const LANGUAGE_ATTRIBUTES = gql`
  fragment LanguageAttributes on Language {
    id
    nameEn
    namePl
  }
`;

export const LANGUAGE_QUERY = gql`
  query Language($id: ID!) {
    language(id: $id) {
      ...LanguageAttributes
    }
  }
  ${LANGUAGE_ATTRIBUTES}
`;

export const LANGUAGES_QUERY = gql`
  query Languages {
    languages {
      ...LanguageAttributes
    }
  }
  ${LANGUAGE_ATTRIBUTES}
`;

export const CREATE_LANGUAGE_MUTATION = gql`
  mutation CreateLanguage($data: LanguageInput!) {
    createLanguage(data: $data) {
      ...LanguageAttributes
    }
  }
  ${LANGUAGE_ATTRIBUTES}
`;

export const DELETE_LANGUAGE_MUTATION = gql`
  mutation DeleteLanguage($id: ID!) {
    deleteLanguage(id: $id) {
      id
    }
  }
`;

export const UPDATE_LANGUAGE_MUTATION = gql`
  mutation UpdateLanguage($id: ID!, $data: LanguageInput!) {
    updateLanguage(id: $id, data: $data) {
      ...LanguageAttributes
    }
  }
  ${LANGUAGE_ATTRIBUTES}
`;
