import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useCustomQuery } from "../hooks/useCustomQuery";
import { Teams } from "../__generated__/types";
import { TEAMS_QUERY } from "../gql/teams";
import ErrorPage from "./ErrorPage";
import Loading from "./Loading";
import ListView from "./ListView";
import TeamCard from "./TeamCard";

const TeamList: React.FC<RouteComponentProps> = ({ history }) => {
  const { errorCode, loading, data } = useCustomQuery<Teams>({
    query: TEAMS_QUERY,
  });

  if (loading) {
    return <Loading />;
  }

  if (data) {
    return (
      <ListView
        title="Teams"
        entities={data.teams}
        filterFn={({ agent: { profile } }, query) =>
          profile.name.toLowerCase().indexOf(query) !== -1
        }
        sortFn={(a, b) =>
          Intl.Collator().compare(a.agent.profile.name, b.agent.profile.name)
        }
        onNewClick={() => history.push("/teams/new")}
      >
        {({ entities }) =>
          entities.map((e) => (
            <TeamCard key={e.id} team={e} history={history} />
          ))
        }
      </ListView>
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default TeamList;
