import { PureQueryOptions } from "@apollo/client";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import { ExecutionResult } from "graphql";
import React, { PropsWithChildren } from "react";

interface Props<T> {
  deletedItem: T | null;
  nameFn: (item: T) => string;
  handleDelete: (id: string) => Promise<ExecutionResult>;
  handleClose: () => void;
  handleRedirect?: () => void;
  refetchQueries?: PureQueryOptions[];
}

const DeleteModal = <T extends Entity>({
  deletedItem,
  nameFn,
  handleDelete,
  handleClose,
  handleRedirect,
}: PropsWithChildren<Props<T>>) => {
  return deletedItem ? (
    <Dialog
      isOpen
      canEscapeKeyClose
      canOutsideClickClose
      isCloseButtonShown
      enforceFocus
      usePortal
      title="Confirmation required"
      icon="trash"
      onClose={handleClose}
    >
      <div className={Classes.DIALOG_BODY}>
        <Message entity={deletedItem} name={nameFn(deletedItem)} />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button type="button" intent="none" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            intent="danger"
            onClick={async () => {
              if (!deletedItem) return;
              try {
                await handleDelete(deletedItem.id);
                handleRedirect ? handleRedirect() : handleClose();
              } catch (_error) {
                handleClose();
              }
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Dialog>
  ) : null;
};

interface MessageProps {
  name: string;
  entity: Entity;
}

const Message: React.FC<MessageProps> = ({ name, entity }) => {
  switch (entity.__typename) {
    case "Book":
      return (
        <p>
          Are you absolutely, positively sure you want to delete the&nbsp;
          <strong>{name}</strong> book profile?
        </p>
      );
    case "Catalog":
      return (
        <p>
          Are you absolutely, positively sure you want to delete the&nbsp;
          <strong>{name}</strong> catalog?
        </p>
      );
    case "Language":
      return (
        <p>
          Are you absolutely, positively sure you want to delete the&nbsp;
          <strong>{name}</strong> language?
        </p>
      );
    case "Team":
      return (
        <p>
          Are you absolutely, positively sure you want to delete&nbsp;
          <strong>{name}&apos;s</strong> team?
        </p>
      );
    default:
      return (
        <p>
          Are you absolutely, positively sure you want to delete&nbsp;
          <strong>{name}</strong>?
        </p>
      );
  }
};

export default DeleteModal;
