import { DataProxy } from "@apollo/client";
import { ExecutionResult, DocumentNode } from "apollo-link";

interface UpdateCacheProps<TCData, TCVars, TData> {
  cache: {
    readQuery(
      options: DataProxy.Query<TCVars>,
      optimistic?: boolean
    ): TCData | null;
    writeQuery(options: DataProxy.WriteQueryOptions<TCData, TCVars>): void;
  };
  result: ExecutionResult<TData>;
  cachedQuery: {
    query: DocumentNode;
    variables?: TCVars;
  };
  update: (cachedData: TCData, resultData: TData) => TCData;
}

export function updateCache<TCData, TCVars, TData>({
  cache,
  result,
  cachedQuery,
  update,
}: UpdateCacheProps<TCData, TCVars, TData>) {
  try {
    // readQuery will throw an error if I'm trying to read data for a query that
    // hasn't been cached yet (which is a terrible API, but oh well...)
    const cached = cache.readQuery(cachedQuery);
    if (cached && result.data) {
      cache.writeQuery({
        ...cachedQuery,
        data: update(cached, result.data),
      });
    }
  } catch (error) {}
}
