import React from "react";
import { Button } from "@blueprintjs/core";
import s from "../stylesheets/FormButtons.module.scss";

interface Props {
  onBackClick?: () => void;
  onDeleteClick?: () => void;
  submitDisabled: boolean;
}

const FormButtons: React.FC<Props> = ({
  onBackClick,
  onDeleteClick,
  submitDisabled,
}) => {
  return (
    <div className={s.wrapper}>
      {onBackClick ? (
        <Button
          minimal
          large
          type="button"
          intent="none"
          icon="circle-arrow-left"
          onClick={onBackClick}
          className={s.button}
        >
          Back
        </Button>
      ) : null}
      {onDeleteClick ? (
        <Button
          minimal
          large
          type="button"
          intent="danger"
          icon="delete"
          onClick={onDeleteClick}
          className={s.button}
        >
          Delete
        </Button>
      ) : null}
      <Button
        minimal
        large
        type="submit"
        intent="primary"
        disabled={submitDisabled}
        icon="confirm"
        className={s.button}
      >
        Save
      </Button>
    </div>
  );
};

export default FormButtons;
