import { MutationUpdaterFn } from "@apollo/client";
import { TERRITORIES_QUERY } from "../gql/territories";
import { updateCache } from "../utils/updateCache";
import { DeleteTerritory, Territories } from "../__generated__/types";

export const deleteTerritoryUpdate: MutationUpdaterFn<DeleteTerritory> = (
  cache,
  result
) => {
  updateCache<Territories, unknown, DeleteTerritory>({
    cache,
    result,
    cachedQuery: { query: TERRITORIES_QUERY },
    update: ({ territories }, { deleteTerritory: { id } }) => ({
      territories: territories.filter((cached) => cached.id !== id),
    }),
  });

  if (result.data?.deleteTerritory) {
    const { id, __typename } = result.data.deleteTerritory;
    cache.evict({ id: `${__typename}:${id}` });
    cache.gc();
  }
};
