import React, { FC } from "react";
import { FormGroup, Intent } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { useField } from "formik";

interface Props {
  name: string;
  label: string;
  helperText: string;
}

const DateInputField: FC<Props> = ({ label, helperText, name }) => {
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField(name);
  const errorMsg = touched && error;
  const formatDate = (date: Date): string =>
    new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
  const parseDate = (str: string): Date => new Date(str);
  const intent = errorMsg ? Intent.DANGER : Intent.NONE;
  return (
    <FormGroup
      label={label}
      helperText={errorMsg ? errorMsg : helperText}
      labelFor={name}
      intent={intent}
    >
      <DateInput
        value={value}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder="YYYY-MM-DD"
        onChange={(newDate: Date, isUserSet: boolean) =>
          isUserSet && setValue(newDate)
        }
        inputProps={{
          large: true,
          onBlur,
          name,
        }}
      />
    </FormGroup>
  );
};

export default DateInputField;
