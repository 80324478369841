import React, { FC, useContext } from "react";
import { Route, Switch, RouteProps } from "react-router-dom";
import { LoginContext } from "../context/loginContext";
import AuthorEdit from "./AuthorEdit";
import AuthorNew from "./AuthorNew";
import AuthorList from "./AuthorList";
import BookEdit from "./BookEdit";
import BookNew from "./BookNew";
import CatalogEdit from "./CatalogEdit";
import CatalogNew from "./CatalogNew";
import ClientEdit from "./ClientEdit";
import ClientNew from "./ClientNew";
import Clients from "./Clients";
import ErrorPage from "./ErrorPage";
import Languages from "./Languages";
import LanguageNew from "./LanguageNew";
import LanguageEdit from "./LanguageEdit";
import Teams from "./Teams";
import TeamNew from "./TeamNew";
import TeamEdit from "./TeamEdit";
import Home from "./Home";
import Nav from "./Nav";
import Users from "./Users";
import UserNew from "./UserNew";
import UserEdit from "./UserEdit";
import UserProfile from "./UserProfile";
import Territories from "./Territories";
import TerritoryNew from "./TerritoryNew";
import TerritoryEdit from "./TerritoryEdit";
import s from "../stylesheets/Root.module.scss";

const Root: FC = () => {
  return (
    <React.Fragment>
      <Route render={(props) => <Nav {...props} />} />
      <div className={s.wrapper}>
        <div className={s.main}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/clients" component={Clients} />
            <Route exact path="/clients/new" component={ClientNew} />
            <Route
              exact
              path="/clients/:clientID/edit"
              component={ClientEdit}
            />
            <Route
              exact
              path="/catalog/:catalogID/edit"
              component={CatalogEdit}
            />
            <Route exact path="/catalogs/new" component={CatalogNew} />
            <Route exact path="/authors" component={AuthorList} />
            <Route exact path="/authors/new" component={AuthorNew} />
            <Route exact path="/books/new" component={BookNew} />
            <Route exact path="/books/:bookID/edit" component={BookEdit} />
            <Route
              exact
              path="/authors/:authorID/edit"
              component={AuthorEdit}
            />
            <Route exact path="/user-profile" component={UserProfile} />
            <AdminRoute exact path="/languages" component={Languages} />
            <AdminRoute exact path="/languages/new" component={LanguageNew} />
            <AdminRoute
              exact
              path="/language/:langID/edit"
              component={LanguageEdit}
            />
            <AdminRoute exact path="/teams" component={Teams} />
            <AdminRoute exact path="/teams/new" component={TeamNew} />
            <AdminRoute exact path="/teams/:teamID/edit" component={TeamEdit} />
            <AdminRoute exact path="/users" component={Users} />
            <AdminRoute exact path="/users/new" component={UserNew} />
            <AdminRoute exact path="/users/:userID/edit" component={UserEdit} />
            <AdminRoute exact path="/territories" component={Territories} />
            <AdminRoute
              exact
              path="/territories/new"
              component={TerritoryNew}
            />
            <AdminRoute
              exact
              path="/territories/:territoryID/edit"
              component={TerritoryEdit}
            />
            <Route component={ErrorPage} />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  );
};

const AdminRoute: React.FC<RouteProps> = (props) => {
  const [{ user }] = useContext(LoginContext);
  return user?.me.admin ? <Route {...props} /> : null;
};

export default Root;
