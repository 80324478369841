import React, { FC, useEffect } from "react";
import { useField } from "formik";
import { MenuItem, FormGroup, Button, Intent } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

interface Props {
  name: string;
  label: string;
  helperText: string;
  items: Option[];
}

const SelectField: FC<Props> = ({ label, helperText, items, ...props }) => {
  const [{ value }, { touched, error }, { setValue }] = useField<string>(props);
  useEffect(() => {
    if (value === "") {
      setValue(items[0].id);
    }
  }, [items, value, setValue]);
  const TypedSelect = Select.ofType<Option>();
  const currentItem = items.find((item) => item.id === value) || items[0];
  const errorMsg = touched && error;
  return (
    <FormGroup
      label={label}
      helperText={errorMsg ? errorMsg : helperText}
      intent={errorMsg ? Intent.DANGER : Intent.NONE}
    >
      <TypedSelect
        items={items}
        itemRenderer={(item, { modifiers, handleClick }) =>
          modifiers.matchesPredicate ? (
            <MenuItem
              active={modifiers.active}
              key={item.id}
              onClick={handleClick}
              text={item.display}
              shouldDismissPopover={false}
            />
          ) : null
        }
        itemsEqual="id"
        onItemSelect={(item) => setValue(item.id)}
        popoverProps={{ minimal: true }}
        filterable={false}
      >
        <Button
          type="button"
          text={currentItem.display}
          rightIcon="double-caret-vertical"
          large
        />
      </TypedSelect>
    </FormGroup>
  );
};

export default SelectField;
