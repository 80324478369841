import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useCustomQuery } from "../hooks/useCustomQuery";
import { Languages } from "../__generated__/types";
import { LANGUAGES_QUERY } from "../gql/languages";
import ErrorPage from "./ErrorPage";
import Loading from "./Loading";
import ListView from "./ListView";
import LanguageCard from "./LanguageCard";

const LanguageList: React.FC<RouteComponentProps> = ({ history }) => {
  const { errorCode, loading, data } = useCustomQuery<Languages>({
    query: LANGUAGES_QUERY,
  });

  if (loading) {
    return <Loading />;
  }

  if (data?.languages) {
    return (
      <ListView
        title="Languages"
        entities={data.languages}
        filterFn={(item, query) =>
          item.nameEn.toLowerCase().indexOf(query) !== -1 ||
          item.namePl.toLowerCase().indexOf(query) !== -1
        }
        sortFn={(a, b) => Intl.Collator().compare(a.nameEn, b.nameEn)}
        onNewClick={() => history.push("/languages/new")}
      >
        {({ entities }) =>
          entities.map((e) => (
            <LanguageCard key={e.id} language={e} history={history} />
          ))
        }
      </ListView>
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default LanguageList;
