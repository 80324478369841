import axios from "axios";

export async function uploadWithProgress(
  url: string,
  file: File,
  headers: { [key: string]: string },
  progressCallback: (progress: number) => void
): Promise<void> {
  await axios.put(url, file, {
    headers,
    onUploadProgress: progressCallback
      ? (progressEvent: { loaded: number; total: number }) =>
          progressCallback(progressEvent.loaded / progressEvent.total)
      : undefined
  });
}
