import React, { FC } from "react";
import {
  DeleteCatalog,
  DeleteCatalogVariables,
  MyClient_myClient_catalogs,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { DELETE_CATALOG_MUTATION } from "../gql/catalogs";
import DeleteModal from "./DeleteModal";
import { deleteCatalogUpdate } from "../updateFunctions/deleteCatalogUpdate";

interface Props {
  redirectCallback?: () => void;
  deletedCatalog: MyClient_myClient_catalogs | null;
  handleClose: () => void;
  clientID: string;
}

const DelCatalog: FC<Props> = ({
  deletedCatalog,
  handleClose,
  redirectCallback,
  clientID,
}) => {
  const deleteCatalog = useCustomMutation<
    DeleteCatalog,
    DeleteCatalogVariables
  >({
    mutation: DELETE_CATALOG_MUTATION,
    successMsg: "Catalog deleted successfully",
  });
  return deletedCatalog ? (
    <DeleteModal
      deletedItem={deletedCatalog}
      nameFn={(item) => item.name}
      handleClose={handleClose}
      handleRedirect={redirectCallback}
      handleDelete={(id) =>
        deleteCatalog({
          variables: { id },
          update: deleteCatalogUpdate(clientID),
        })
      }
    />
  ) : null;
};

export default DelCatalog;
