import React from "react";
import { History } from "history";
import { Card, H4 } from "@blueprintjs/core";
import { Teams_teams } from "../__generated__/types";
import s from "../stylesheets/TeamCard.module.scss";

interface Props {
  team: Teams_teams;
  history: History;
}

const TeamCard: React.FC<Props> = ({ team, history }) => {
  return (
    <Card
      elevation={2}
      className={s.card}
      interactive
      onClick={() => history.push(`/teams/${team.id}/edit`)}
    >
      <React.Fragment>
        <H4 className={s.agent}>{team.agent.profile.name}</H4>
        <p className={s.assistants}>
          {team.assistants?.map((a) => a.profile.name).join(", ")}
        </p>
      </React.Fragment>
    </Card>
  );
};

export default TeamCard;
