import { MutationUpdaterFn } from "@apollo/client";
import { CreateAuthor, MyAuthors, Authors } from "../__generated__/types";
import { updateCache } from "../utils/updateCache";
import { MYAUTHORS_QUERY, AUTHORS_QUERY } from "../gql/authors";

export const createAuthorUpdate: MutationUpdaterFn<CreateAuthor> = (
  cache,
  result
) => {
  // MYAUTHORS_QUERY
  updateCache<MyAuthors, {}, CreateAuthor>({
    cache,
    result,
    cachedQuery: { query: MYAUTHORS_QUERY },
    update: ({ myAuthors }, { createAuthor }) => ({
      myAuthors: myAuthors.concat([createAuthor]),
    }),
  });

  // AUTHORS_QUERY
  updateCache<Authors, {}, CreateAuthor>({
    cache,
    result,
    cachedQuery: { query: AUTHORS_QUERY },
    update: ({ authors }, { createAuthor: { id, __typename, name } }) => ({
      authors: authors.concat([{ id, __typename, name }]),
    }),
  });
};
