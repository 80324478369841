import React, { FC } from "react";
import { History } from "history";
import { H3, Button, NonIdealState } from "@blueprintjs/core";
import { MyAuthor_myAuthor_books } from "../__generated__/types";
import BookCard from "./BookCard";
import s from "../stylesheets/BookSidebar.module.scss";
import { useDeleteListItem } from "../hooks/useDeleteListItem";
import DeleteBook from "./DeleteBook";

interface Props {
  books: MyAuthor_myAuthor_books[];
  authorID: string;
  history: History;
}

const BookSidebar: FC<Props> = ({ books, authorID, history }) => {
  const { delItem, onDelClick, onClose } = useDeleteListItem<
    MyAuthor_myAuthor_books
  >();

  const AddNewBookBtn = (
    <Button
      intent="primary"
      text="Add"
      icon="add"
      onClick={() => history.push("/books/new", { authorID })}
    />
  );

  const sortedBooks = [...books].sort((a, b) =>
    a.pubYear < b.pubYear
      ? 1
      : a.pubYear > b.pubYear
      ? -1
      : a.titleEn < b.titleEn
      ? 1
      : a.titleEn > b.titleEn
      ? -1
      : 0
  );

  return (
    <div className={s.root}>
      {books.length === 0 ? (
        <NonIdealState
          icon="book"
          title="No books"
          description="There are no books associated with this author yet..."
          action={AddNewBookBtn}
        />
      ) : (
        <React.Fragment>
          <div className={s.header}>
            <H3 className={s.title}>Books</H3>
            {AddNewBookBtn}
          </div>
          {sortedBooks.map((b) => (
            <BookCard
              key={b.id}
              book={b}
              authorID={authorID}
              history={history}
              deleteHandler={onDelClick}
            />
          ))}
          <DeleteBook deletedBook={delItem} handleClose={onClose} />
        </React.Fragment>
      )}
    </div>
  );
};

export default BookSidebar;
