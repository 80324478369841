import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import {
  UpdateTeam,
  UpdateTeamVariables,
  Team,
  TeamVariables,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { TEAM_QUERY } from "../gql/teams";
import { UPDATE_TEAM_MUTATION } from "../gql/teams";
import TeamForm from "./TeamForm";
import FormView from "./FormView";
import ErrorPage from "./ErrorPage";
import { useCustomQuery } from "../hooks/useCustomQuery";
import Loading from "./Loading";

const TeamEdit: FC<RouteComponentProps<{ teamID: string }>> = ({
  match,
  history: { push },
}) => {
  const updateTeam = useCustomMutation<UpdateTeam, UpdateTeamVariables>({
    mutation: UPDATE_TEAM_MUTATION,
    successMsg: "Team updated successfully",
    redirectCallback: () => push("/teams"),
  });

  const { loading, data, errorCode } = useCustomQuery<Team, TeamVariables>({
    query: TEAM_QUERY,
    variables: { id: match.params.teamID },
  });

  if (loading) {
    return <Loading />;
  }

  if (data?.team) {
    const { team } = data;
    const initialValues: TeamValues = {
      agentID: team.agent.id,
      assistantIDs: team.assistants ? team.assistants.map((a) => a.id) : [],
    };
    return (
      <FormView
        title="Edit Team"
        main={
          <TeamForm
            prevTeam={team}
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await updateTeam({
                  variables: { id: team.id, data: values },
                });
              } catch (error) {
                setSubmitting(false);
              }
            }}
            onBackClick={() => push("/teams")}
          />
        }
      />
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default TeamEdit;
