import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { CreateAuthor, CreateAuthorVariables } from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { CREATE_AUTHOR_MUTATION } from "../gql/authors";
import AuthorForm from "./AuthorForm";
import FormView from "./FormView";
import { createAuthorUpdate } from "../updateFunctions/createAuthorUpdate";

const AuthorNew: FC<RouteComponentProps> = ({ history }) => {
  const createAuthor = useCustomMutation<CreateAuthor, CreateAuthorVariables>({
    mutation: CREATE_AUTHOR_MUTATION,
    successMsg: "Author created successfully",
    redirectCallback: () => history.push("/authors"),
  });

  const initialValues: AuthorValues = {
    name: "",
    photoListKey: "",
    photoProfileKey: "",
    bioEn: "",
    bioPl: "",
    published: true,
    agentID: "",
  };

  return (
    <FormView
      title="New Author"
      main={
        <AuthorForm
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await createAuthor({
                variables: { data: values },
                update: createAuthorUpdate,
              });
            } catch (error) {
              setSubmitting(false);
            }
          }}
        />
      }
    />
  );
};

export default AuthorNew;
