import { useState, MouseEvent } from "react";

export function useDeleteListItem<T extends Entity>() {
  const [delItem, setDelItem] = useState<T | null>(null);
  const onDelClick = (e: MouseEvent<HTMLElement>, item: T) => {
    e.preventDefault();
    e.stopPropagation();
    setDelItem(item);
  };
  const onClose = () => setDelItem(null);
  return { delItem, onDelClick, onClose };
}
