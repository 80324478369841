import React, { useEffect, useState, FC, ChangeEvent } from "react";
import { useField } from "formik";
import {
  FormGroup,
  ControlGroup,
  FileInput,
  Spinner,
  Icon,
} from "@blueprintjs/core";
import { useFileUpload } from "../hooks/useFileUpload";
import s from "../stylesheets/FileInputField.module.scss";

interface Props {
  name: string;
  acl?: Acl;
  fileType: string;
  helperText: string;
  label?: string;
  uploadPrefix: string;
  setUploading: (isUploading: boolean) => void;
  preview?: JSX.Element;
}

const FileInputField: FC<Props> = ({
  name,
  acl = "private",
  fileType,
  uploadPrefix,
  setUploading,
  helperText,
  label,
  preview,
}) => {
  const [
    { value, onBlur },
    { touched, error },
    { setValue, setError },
  ] = useField(name);
  const [fileName, setFileName] = useState("");
  const { upload, isUploading, progress } = useFileUpload(uploadPrefix, acl);

  // strip the prefix from the filename for display purposes
  useEffect(() => {
    if (typeof value === "string" && value.startsWith(uploadPrefix)) {
      const regex = new RegExp(`^${uploadPrefix}?(.*/)`, "g");
      setFileName(value.replace(regex, ""));
    }
  }, [value, uploadPrefix]);

  // pass isUploading status to the parent form
  useEffect(() => {
    setUploading(isUploading);
  }, [setUploading, isUploading]);

  async function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setValue("");
    setError("");

    if (!e.target.files) {
      setError("File list is empty");
      return;
    }
    const file = e.target.files[0];
    setFileName(file.name);

    try {
      const key = await upload(file);
      setValue(key);
    } catch (e) {
      setError(e.message ? e.message : "Error uploading file");
    }
  }

  const errorMsg = touched && error;

  return (
    <FormGroup
      label={label}
      helperText={errorMsg ? errorMsg : helperText}
      intent={errorMsg ? "danger" : "none"}
    >
      {preview ? preview : null}
      <ControlGroup>
        <FileInput
          large
          hasSelection={fileName !== ""}
          text={fileName ? fileName : "Choose a file..."}
          inputProps={{
            name,
            onBlur,
            accept: fileType,
          }}
          className={s.input}
          onInputChange={handleInputChange}
        />
        <div className={s.tag}>
          {progress > 0 && progress < 1 && (
            <Spinner size={22} value={progress} />
          )}
          {progress === 1 && <Icon icon="tick" intent="success" />}
        </div>
      </ControlGroup>
    </FormGroup>
  );
};

export default FileInputField;
