import { useMutation, MutationFunction } from "@apollo/client";
import { DocumentNode } from "graphql";
import { processServerErrors } from "../utils/processServerErrors";
import { AppToaster } from "../toaster";

interface Props {
  mutation: DocumentNode;
  successMsg?: string;
  redirectCallback?: () => void;
}

export function useCustomMutation<TData, TVariables>({
  successMsg,
  mutation,
  redirectCallback,
}: Props): MutationFunction<TData, TVariables> {
  const [mutate] = useMutation<TData, TVariables>(mutation, {
    onCompleted: () => {
      if (successMsg)
        AppToaster.show({ message: successMsg, intent: "success" });
      if (redirectCallback) redirectCallback();
    },
    onError: (error) => processServerErrors(error),
  });
  return mutate;
}
