import { gql } from "@apollo/client";

export const PROFILE_NAME = gql`
  fragment ProfileName on Profile {
    id
    name
  }
`;

const PROFILE_ATTRIBUTES = gql`
  fragment ProfileAttributes on Profile {
    id
    name
    bioEn
    bioPl
    positionEn
    positionPl
    published
    photo
    photoKey
  }
`;

export const MYPROFILE_QUERY = gql`
  query MyProfile {
    myProfile {
      ...ProfileAttributes
    }
  }
  ${PROFILE_ATTRIBUTES}
`;

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfile($id: ID!, $data: ProfileInput!) {
    updateProfile(id: $id, data: $data) {
      ...ProfileAttributes
    }
  }
  ${PROFILE_ATTRIBUTES}
`;
