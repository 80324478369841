import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import HomeCard from "./HomeCard";
import s from "../stylesheets/Home.module.scss";
import { LoginContext } from "../context/loginContext";

const Home: FC<RouteComponentProps> = () => {
  const [{ user }] = React.useContext(LoginContext);
  return (
    <div className={s.wrapper}>
      <HomeCard
        to="/clients"
        title="Clients"
        desc="Manage client profiles and catalogs"
        icon="office"
      />
      <HomeCard
        to="/authors"
        title="Authors"
        desc="Manage author profiles and books"
        icon="git-repo"
      />
      <HomeCard
        to="/user-profile"
        title="Profile"
        desc="Manage your individual user profile"
        icon="user"
      />
      {user?.me.admin && (
        <>
          <HomeCard
            to="/languages"
            title="Languages"
            desc="Manage the list of languages"
            icon="translate"
          />
          <HomeCard
            to="/teams"
            title="Teams"
            desc="Manage agents and teams"
            icon="social-media"
          />
          <HomeCard
            to="/territories"
            title="Territories"
            desc="Manage territories"
            icon="globe"
          />
          <HomeCard
            to="/users"
            title="Users"
            desc="Manage users"
            icon="people"
          />
        </>
      )}
    </div>
  );
};

export default Home;
