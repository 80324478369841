import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import { MyClients } from "../__generated__/types";
import { useCustomQuery } from "../hooks/useCustomQuery";
import { MYCLIENTS_QUERY } from "../gql/clients";
import ClientCard from "./ClientCard";
import ErrorPage from "./ErrorPage";
import ListView from "./ListView";
import Loading from "./Loading";

const Clients: FC<RouteComponentProps> = ({ history }) => {
  const { errorCode, loading, data } = useCustomQuery<MyClients>({
    query: MYCLIENTS_QUERY,
  });

  if (loading) {
    return <Loading />;
  }

  if (data) {
    return (
      <ListView
        title="Clients"
        entities={data.myClients}
        filterFn={({ name }, query) => name.toLowerCase().indexOf(query) !== -1}
        sortFn={(a, b) => Intl.Collator().compare(a.name, b.name)}
        onNewClick={() => history.push("/clients/new")}
      >
        {({ entities }) =>
          entities.map((e) => (
            <ClientCard key={e.id} client={e} history={history} />
          ))
        }
      </ListView>
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default Clients;
