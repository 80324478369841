import React, { ReactElement, ReactNode } from "react";
import { H1, Button } from "@blueprintjs/core";
import { useTextFilter } from "../hooks/useTextFilter";
import Search from "./Search";
import s from "../stylesheets/ListView.module.scss";

interface Props<T> {
  entities: T[];
  filterFn: (item: T, query: string) => boolean;
  sortFn: (itemA: T, itemB: T) => number;
  title: string;
  onNewClick: () => void;
  children: (props: { entities: T[] }) => ReactNode;
}

const ListView = <T extends Entity>({
  entities,
  sortFn,
  filterFn,
  title,
  onNewClick,
  children,
}: Props<T>): ReactElement<Props<T>> => {
  const [query, reset, onChange] = useTextFilter();
  return (
    <div className={s.root}>
      <div className={s.header}>
        <div>
          <H1 className={s.title}>{title}</H1>
        </div>
        <div className={s.search}>
          <Search query={query} reset={reset} onChange={onChange} />
          <Button large icon="add" intent="primary" onClick={onNewClick}>
            Add New
          </Button>
        </div>
      </div>
      <div className={s.wrapper}>
        {children({
          entities: [...entities]
            .filter((item) => filterFn(item, query.toLowerCase()))
            .sort(sortFn),
        })}
      </div>
    </div>
  );
};

export default ListView;
