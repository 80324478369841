import React, { FC, memo } from "react";
import classNames from "classnames";
import { History } from "history";
import { Card, H5, Button } from "@blueprintjs/core";
import { MyAuthor_myAuthor_books } from "../__generated__/types";
import { BUCKET_ROOT, COVER_PLACEHOLDER } from "../config/constants";
import DetailTagRow from "./DetailTagRow";
import DetailTag from "./DetailTag";
import s from "../stylesheets/BookCard.module.scss";

interface Props {
  book: MyAuthor_myAuthor_books;
  history: History;
  authorID: string;
  deleteHandler: (
    event: React.MouseEvent<HTMLElement>,
    catalog: MyAuthor_myAuthor_books
  ) => void;
}

const BookCard: FC<Props> = ({
  book,
  history: { push },
  authorID,
  deleteHandler,
}) => {
  return (
    <Card
      elevation={2}
      key={book.id}
      className={s.root}
      interactive
      onClick={() => push(`/books/${book.id}/edit`, { prevAuthorID: authorID })}
    >
      <div className={s.outer}>
        <div>
          {book.coverKey ? (
            <img src={BUCKET_ROOT + book.coverKey} alt={book.titleEn} />
          ) : (
            <img src={COVER_PLACEHOLDER} alt="placeholder" />
          )}
        </div>
        <div className={s.inner}>
          <div>
            <H5 className={s.title}>{book.titleEn}</H5>
            <p className={classNames(s.title, s.titlePl)}>{book.titlePl}</p>
          </div>
          <Button
            small
            minimal
            className={s.close}
            icon="cross"
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              deleteHandler(e, book)
            }
          />
          <DetailTagRow>
            <DetailTag
              visible
              intent="none"
              content={book.pubYear.toString()}
            />
            <DetailTag
              visible={book.transLangs.length > 0}
              intent="none"
              icon="globe"
              content={book.transLangs.length.toString()}
            />
            <DetailTag
              visible={!book.published}
              intent="primary"
              content="Unpublished"
            />
          </DetailTagRow>
        </div>
      </div>
    </Card>
  );
};

export default memo(BookCard);
