import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import {
  UpdateBook,
  UpdateBookVariables,
  MyBook,
  MyBookVariables,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { UPDATE_BOOK_MUTATION, MYBOOK_QUERY } from "../gql/books";
import { useCustomQuery } from "../hooks/useCustomQuery";
import FormView from "./FormView";
import BookForm from "./BookForm";
import ErrorPage from "./ErrorPage";
import Loading from "./Loading";
import { updateBookUpdate } from "../updateFunctions/updateBookUpdate";

interface RouteState {
  prevAuthorID?: string;
}

const BookEdit: FC<RouteComponentProps<
  { bookID: string },
  {},
  RouteState | undefined
>> = ({
  match: {
    params: { bookID },
  },
  location,
  history,
}) => {
  // A slight difficulty comes from the fact that it's only possible access
  // books from the profile of an author.  It's also possible to change an
  // author of the book. Finally, a book can have multiple authors.
  //
  // In other words, neither initial values nor submitted values can give me a
  // single id of the author detail page to redirect to.
  //
  // I therefore (reluctantly) have to rely on route state to give me the id of
  // the original author from whose profile the user has reached the book detail
  // page (prevAuthorID), so that the app can redirect back to that page after
  // submitting the form changes.
  //
  // The list of authors page serves as fallback in case the user navigated to
  // the book detail page directly using an URL.

  const prevAuthorID = location.state?.prevAuthorID;
  const redirectPath = prevAuthorID
    ? `/authors/${prevAuthorID}/edit`
    : "/authors";

  const updateBook = useCustomMutation<UpdateBook, UpdateBookVariables>({
    mutation: UPDATE_BOOK_MUTATION,
    successMsg: "Book updated successfully",
    redirectCallback: () => history.push(redirectPath),
  });

  const { loading, data, errorCode } = useCustomQuery<MyBook, MyBookVariables>({
    query: MYBOOK_QUERY,
    variables: { id: bookID },
  });

  if (loading) {
    return <Loading />;
  }

  if (data && data.myBook) {
    const { myBook: book } = data;
    const initialValues: BookValues = {
      titleEn: book.titleEn,
      titlePl: book.titlePl,
      subtitleEn: book.subtitleEn || "",
      subtitlePl: book.subtitlePl || "",
      descriptionEn: book.descriptionEn,
      descriptionPl: book.descriptionPl,
      coverKey: book.coverKey || "",
      publisher: book.publisher || "",
      pubYear: book.pubYear,
      published: book.published,
      authorIDs: book.authors.map((a) => a.id),
      transLangIDs: book.transLangs.map((t) => t.id),
    };

    return (
      <FormView
        title={book.titleEn}
        main={
          <BookForm
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              const prevAuthorIDs = book.authors.map((a) => a.id);
              const nextAuthorIDs = values.authorIDs;
              try {
                updateBook({
                  variables: { id: bookID, data: values },
                  update: updateBookUpdate(prevAuthorIDs, nextAuthorIDs),
                });
              } catch (error) {
                actions.setSubmitting(false);
              }
            }}
            onBackClick={() => history.push(redirectPath)}
            prevBook={book}
            prevAuthorID={prevAuthorID}
          />
        }
      />
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default BookEdit;
