import React, { FC, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { FormikHelpers, Formik, Form } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import {
  MyAuthor_myAuthor_books,
  Authors,
  Languages,
} from "../__generated__/types";
import { BOOK_COVER_PREFIX } from "../config/constants";
import CheckboxField from "./CheckboxField";
import FileInputField from "./FileInputField";
import ImgPreview from "./ImgPreview";
import NumericInputField from "./NumericInputField";
import TextAreaField from "./TextAreaField";
import TextField from "./TextField";
import s from "../stylesheets/BookForm.module.scss";
import { useCustomQuery } from "../hooks/useCustomQuery";
import ErrorPage from "./ErrorPage";
import Loading from "./Loading";
import MultiSelectField from "./MultiSelectField";
import FormButtons from "./FormButtons";
import DeleteBook from "./DeleteBook";
import { AUTHORS_QUERY } from "../gql/authors";
import { LANGUAGES_QUERY } from "../gql/languages";

interface Props extends RouteComponentProps {
  initialValues: BookValues;
  onSubmit: (
    values: BookValues,
    formikHelpers: FormikHelpers<BookValues>
  ) => void;
  onBackClick?: () => void;
  prevBook?: MyAuthor_myAuthor_books | null;
  prevAuthorID?: string;
}

const requiredMsg = "Required";

const validationSchema = Yup.object().shape({
  titleEn: Yup.string().required(requiredMsg),
  titlePl: Yup.string().required(requiredMsg),
  subtitleEn: Yup.string(),
  subtitlePl: Yup.string(),
  descriptionEn: Yup.string().required(requiredMsg),
  descriptionPl: Yup.string().required(requiredMsg),
  coverKey: Yup.string(),
  publisher: Yup.string(),
  pubYear: Yup.number()
    .moreThan(1900, "Must be greater than 1900")
    .lessThan(2100, "Must be smaller than 2100")
    .required(requiredMsg),
  published: Yup.bool().required(requiredMsg),
  authorIDs: Yup.array()
    .of(Yup.number().integer())
    .min(1, "At least one author is required")
    .required(requiredMsg),
  transLangIDs: Yup.array().of(Yup.number().integer()),
});

const BookForm: FC<Props> = ({
  initialValues,
  onSubmit,
  onBackClick,
  prevBook,
  prevAuthorID,
  history: { push },
}) => {
  const [isUploading, setUploading] = useState(false);
  const [delItem, setDelItem] = useState<MyAuthor_myAuthor_books | null>(null);

  const {
    errorCode: authorsErrorCode,
    loading: authorsLoading,
    data: authorsData,
  } = useCustomQuery<Authors>({
    query: AUTHORS_QUERY,
  });
  const {
    errorCode: languagesErrorCode,
    loading: languagesLoading,
    data: languagesData,
  } = useCustomQuery<Languages>({
    query: LANGUAGES_QUERY,
  });

  if (authorsLoading || languagesLoading) {
    return <Loading />;
  }

  if (authorsData?.authors && languagesData?.languages) {
    const { authors } = authorsData;
    const { languages } = languagesData;
    return (
      <Formik<BookValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form autoComplete="off">
            <div className={classNames(s.authors, s.row)}>
              <MultiSelectField
                name="authorIDs"
                label="Authors"
                helperText="Select at least one author"
                items={[...authors]
                  .map((author) => ({ id: author.id, display: author.name }))
                  .sort((a, b) =>
                    Intl.Collator().compare(a.display, b.display)
                  )}
              />
            </div>
            <div className={classNames(s.title, s.row)}>
              <TextField
                name="titleEn"
                label="English title"
                helperText="Book's English title"
              />
            </div>
            <div className={classNames(s.title, s.row)}>
              <TextField
                name="titlePl"
                label="Polish title"
                helperText="Book's Polish title"
              />
            </div>
            <div className={classNames(s.title, s.row)}>
              <TextField
                name="subtitleEn"
                label="English subtitle"
                helperText="Book's English subtitle"
              />
            </div>
            <div className={classNames(s.title, s.row)}>
              <TextField
                name="subtitlePl"
                label="Polish subtitle"
                helperText="Book's Polish subtitle"
              />
            </div>
            <div className={classNames(s.description, s.row)}>
              <TextAreaField
                name="descriptionEn"
                label="English Description"
                helperText="Description in English"
                spellCheck="true"
                lang="en"
                rows={8}
              />
            </div>
            <div className={classNames(s.description, s.row)}>
              <TextAreaField
                name="descriptionPl"
                label="Polish Description"
                helperText="Description in Polish"
                spellCheck="true"
                lang="pl"
                rows={8}
              />
            </div>
            <div className={classNames(s.row, s.cover)}>
              <FileInputField
                name="coverKey"
                label="Book cover"
                helperText="Cover design for the book, if empty a placeholder will be used"
                fileType="image/*"
                uploadPrefix={BOOK_COVER_PREFIX}
                setUploading={setUploading}
                acl="public-read"
                preview={
                  <ImgPreview
                    imgKey={values.coverKey}
                    width={200}
                    height={196}
                  />
                }
              />
            </div>
            <div className={classNames(s.publisher, s.row)}>
              <TextField
                name="publisher"
                label="Publisher"
                helperText="The original publisher of the book (optional)"
              />
            </div>
            <div className={classNames(s.pubYear, s.row)}>
              <NumericInputField
                name="pubYear"
                label="Publication year"
                helperText="Year of original publication"
                valueAs="number"
                min={1900}
                max={2030}
              />
            </div>
            <div className={classNames(s.languages, s.row)}>
              <MultiSelectField
                name="transLangIDs"
                label="Translation rights"
                helperText="Languages the book has been translated to"
                items={[...languages]
                  .map((language) => ({
                    id: language.id,
                    display: language.nameEn,
                  }))
                  .sort((a, b) =>
                    Intl.Collator().compare(a.display, b.display)
                  )}
              />
            </div>
            <div className={s.row}>
              <CheckboxField name="published" label="Published" />
            </div>
            <FormButtons
              onBackClick={onBackClick}
              onDeleteClick={
                prevBook && prevAuthorID
                  ? () => setDelItem(prevBook)
                  : undefined
              }
              submitDisabled={isSubmitting || isUploading}
            />
            {prevBook && prevAuthorID && (
              <DeleteBook
                deletedBook={delItem}
                handleClose={() => setDelItem(null)}
                redirectCallback={() => push(`/authors/${prevAuthorID}/edit`)}
              />
            )}
          </Form>
        )}
      </Formik>
    );
  }

  return <ErrorPage code={authorsErrorCode || languagesErrorCode || 500} />;
};

export default withRouter(BookForm);
