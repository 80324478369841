import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import {
  MyProfile,
  UpdateProfile,
  UpdateProfileVariables,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { useCustomQuery } from "../hooks/useCustomQuery";
import { MYPROFILE_QUERY, UPDATE_PROFILE_MUTATION } from "../gql/profiles";
import ErrorPage from "./ErrorPage";
import FormView from "./FormView";
import Loading from "./Loading";
import UserProfileForm from "./UserProfileForm";
import { LoginContext } from "../context/loginContext";

const UserProfile: FC<RouteComponentProps> = ({ history: { goBack } }) => {
  const [, dispatch] = React.useContext(LoginContext);

  const updateProfile = useCustomMutation<
    UpdateProfile,
    UpdateProfileVariables
  >({
    mutation: UPDATE_PROFILE_MUTATION,
    successMsg: "Profile updated successfully",
    redirectCallback: goBack,
  });

  const { errorCode, loading, data } = useCustomQuery<MyProfile>({
    query: MYPROFILE_QUERY,
  });

  if (loading) {
    return <Loading />;
  }

  if (data?.myProfile) {
    const { myProfile } = data;
    const initialValues: UserProfileValues = {
      name: myProfile.name,
      bioEn: myProfile.bioEn,
      bioPl: myProfile.bioPl,
      photoKey: myProfile.photoKey,
      published: myProfile.published,
      positionEn: myProfile.positionEn,
      positionPl: myProfile.positionPl,
    };

    return (
      <FormView
        title="Edit Profile"
        main={
          <UserProfileForm
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const result = await updateProfile({
                  variables: { id: myProfile.id, data: values },
                });
                if (result.data?.updateProfile) {
                  dispatch({
                    type: "PROFILE_UPDATE",
                    payload: result.data.updateProfile,
                  });
                }
              } catch (error) {
                setSubmitting(false);
              }
            }}
          />
        }
      />
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default UserProfile;
