import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  UpdateAuthor,
  UpdateAuthorVariables,
  MyAuthor,
  MyAuthorVariables,
} from "../__generated__/types";
import { MYAUTHOR_QUERY, UPDATE_AUTHOR_MUTATION } from "../gql/authors";
import { useCustomQuery } from "../hooks/useCustomQuery";
import { useCustomMutation } from "../hooks/useCustomMutation";
import AuthorForm from "./AuthorForm";
import BookSidebar from "./BookSidebar";
import ErrorPage from "./ErrorPage";
import FormView from "./FormView";
import Loading from "./Loading";

const AuthorEdit: FC<RouteComponentProps<{ authorID: string }>> = ({
  history,
  match,
}) => {
  const updateAuthor = useCustomMutation<UpdateAuthor, UpdateAuthorVariables>({
    mutation: UPDATE_AUTHOR_MUTATION,
    successMsg: "Author updated successfully",
    redirectCallback: () => history.push("/authors"),
  });

  const { errorCode, loading, data } = useCustomQuery<
    MyAuthor,
    MyAuthorVariables
  >({
    query: MYAUTHOR_QUERY,
    variables: { id: match.params.authorID },
  });

  if (loading) {
    return <Loading />;
  }

  if (data && data.myAuthor) {
    const { myAuthor } = data;
    const initialValues: AuthorValues = {
      name: myAuthor.name,
      photoListKey: myAuthor.photoListKey,
      photoProfileKey: myAuthor.photoProfileKey,
      bioEn: myAuthor.bioEn,
      bioPl: myAuthor.bioPl,
      published: myAuthor.published,
      agentID: myAuthor.agent.id,
    };
    return (
      <FormView
        title={myAuthor.name}
        main={
          <AuthorForm
            prevAuthor={myAuthor}
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await updateAuthor({
                  variables: { id: myAuthor.id, data: values },
                });
              } catch (error) {
                setSubmitting(false);
              }
            }}
          />
        }
        sidebar={
          <BookSidebar
            authorID={myAuthor.id}
            books={myAuthor.books}
            history={history}
          />
        }
      />
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default AuthorEdit;
