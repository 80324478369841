import { MutationUpdaterFn } from "@apollo/client";
import { CreateUser, Users } from "../__generated__/types";
import { updateCache } from "../utils/updateCache";
import { USERS_QUERY } from "../gql/users";

export const createUserUpdate: MutationUpdaterFn<CreateUser> = (
  cache,
  result
) => {
  updateCache<Users, {}, CreateUser>({
    cache,
    result,
    cachedQuery: { query: USERS_QUERY },
    update: ({ users }, { createUser }) => ({
      users: users.concat([createUser]),
    }),
  });
};
