import { gql } from "@apollo/client";
import { USER_ATTRIBUTES } from "./users";
import { TERRITORY_NAMES } from "./territories";
import { CATALOG_ATTRIBUTES } from "./catalogs";

const CLIENT_ATTRIBUTES = gql`
  fragment ClientAttributes on Client {
    id
    name
    slug
    descriptionEn
    descriptionPl
    letters
    website
    published
  }
`;

export const CLIENT_NAME_SLUG = gql`
  fragment ClientNameSlug on Client {
    id
    name
    slug
  }
`;

export const MYCLIENT_QUERY = gql`
  query MyClient($id: ID!) {
    myClient(id: $id) {
      ...ClientAttributes
      agent {
        ...UserAttributes
      }
      territories {
        ...TerritoryNames
      }
      catalogs {
        ...CatalogAttributes
      }
    }
  }
  ${CLIENT_ATTRIBUTES}
  ${USER_ATTRIBUTES}
  ${TERRITORY_NAMES}
  ${CATALOG_ATTRIBUTES}
`;

export const MYCLIENTS_QUERY = gql`
  query MyClients {
    myClients {
      id
      name
      letters
      website
      published
      catalogs {
        id
        dateAdded
      }
    }
  }
`;

export const CREATE_CLIENT_MUTATION = gql`
  mutation CreateClient($data: ClientInput!) {
    createClient(data: $data) {
      ...ClientAttributes
      agent {
        id
      }
      territories {
        id
      }
    }
  }
  ${CLIENT_ATTRIBUTES}
`;

export const DELETE_CLIENT_MUTATION = gql`
  mutation DeleteClient($id: ID!) {
    deleteClient(id: $id) {
      id
    }
  }
`;

export const UPDATE_CLIENT_MUTATION = gql`
  mutation UpdateClient($id: ID!, $data: ClientInput!) {
    updateClient(id: $id, data: $data) {
      ...ClientAttributes
      agent {
        id
      }
      territories {
        id
      }
    }
  }
  ${CLIENT_ATTRIBUTES}
`;
