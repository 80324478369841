import React, { FC } from "react";
import { BUCKET_ROOT } from "../config/constants";
import ImgPlaceholder from "./ImgPlaceholder";
import s from "../stylesheets/ImgPreview.module.scss";

interface Props {
  imgKey: string | null;
  width: number;
  height: number;
}

const ImgPreview: FC<Props> = ({ imgKey, width, height }) => {
  return imgKey ? (
    <div className={s.root} style={{ width, height }}>
      <img src={BUCKET_ROOT + imgKey} alt="preview" />
    </div>
  ) : (
    <ImgPlaceholder width={width} height={height} />
  );
};

export default ImgPreview;
