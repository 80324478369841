import { DocumentNode } from "graphql";
import { useQuery, OperationVariables } from "@apollo/client";
import { processServerErrors } from "../utils/processServerErrors";

interface Props<TVariables> {
  query: DocumentNode;
  variables?: TVariables;
}

export function useCustomQuery<TData, TVariables = OperationVariables>({
  query,
  variables,
}: Props<TVariables>): {
  errorCode: 401 | 404 | 500 | undefined;
  loading: boolean;
  data: TData | undefined;
} {
  const { error, loading, data } = useQuery<TData, TVariables>(query, {
    variables,
  });
  let errorCode: 401 | 404 | 500 | undefined;
  if (error) {
    if (error.message.endsWith("not found")) {
      errorCode = 404;
    } else if (error.message.endsWith("unauthorized")) {
      errorCode = 401;
    } else {
      processServerErrors(error);
      errorCode = 500;
    }
  }
  return { errorCode, loading, data };
}
