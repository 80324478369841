import React, { FC, useContext } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { Navbar, Alignment, Button } from "@blueprintjs/core";
import { LoginContext } from "../context/loginContext";
import GraalLogo from "../assets/img/logo_g.png";
import s from "../stylesheets/Nav.module.scss";

const Nav: FC<RouteComponentProps> = ({
  history: { push },
  location: { pathname },
}) => {
  const [{ user }, dispatch] = useContext(LoginContext);
  return (
    <Navbar className={s.root}>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>
          <Link to="/" className={s.logo}>
            <img src={GraalLogo} alt="Graal Agency Logo" width="40px" />
          </Link>
        </Navbar.Heading>
        <Navbar.Divider />
        <Button
          large
          minimal
          active={pathname === "/clients"}
          icon="git-repo"
          text="Clients"
          onClick={() => push("/clients")}
        />
        <Button
          large
          minimal
          className={s.button}
          active={pathname.startsWith("/clients/new")}
          icon="new-grid-item"
          text="New client"
          onClick={() => push("/clients/new")}
        />
        <Button
          large
          minimal
          className={s.button}
          active={pathname.startsWith("/catalogs/new")}
          icon="document"
          text="New catalog"
          onClick={() => push("/catalogs/new")}
        />
        <Navbar.Divider />
        <Button
          large
          minimal
          active={pathname === "/authors"}
          icon="people"
          text="Authors"
          onClick={() => push("/authors")}
        />
        <Button
          large
          minimal
          className={s.button}
          active={pathname.startsWith("/authors/new")}
          icon="person"
          text="New author"
          onClick={() => push("/authors/new")}
        />
        <Button
          large
          minimal
          className={s.button}
          active={pathname.startsWith("/books/new")}
          icon="book"
          text="New book"
          onClick={() => push("/books/new")}
        />
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <div className={s.userButton}>
          {user?.me.profile.photo ? (
            <img
              className={s.photo}
              src={user?.me.profile.photo}
              alt={user?.me.profile.name}
            />
          ) : (
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              className={s.icon}
            >
              <path
                d="M3.121 15.804A13.936 13.936 0 0110 14c2.5 0 4.847.655 6.879 1.804M13 8a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}

          <span className="bp3-text-large">{user?.me.profile.name}</span>
        </div>
        <Navbar.Divider />
        <Button
          large
          minimal
          icon="log-out"
          onClick={() => dispatch({ type: "LOGOUT" })}
        />
      </Navbar.Group>
    </Navbar>
  );
};

export default Nav;
