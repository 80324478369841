import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import { MyAuthors } from "../__generated__/types";
import { useCustomQuery } from "../hooks/useCustomQuery";
import { MYAUTHORS_QUERY } from "../gql/authors";
import ListView from "./ListView";
import AuthorCard from "./AuthorCard";
import Loading from "./Loading";
import ErrorPage from "./ErrorPage";

const Authors: FC<RouteComponentProps> = ({ history }) => {
  const { errorCode, loading, data } = useCustomQuery<MyAuthors>({
    query: MYAUTHORS_QUERY,
  });

  if (loading) {
    return <Loading />;
  }

  if (data?.myAuthors) {
    return (
      <ListView
        title="Authors"
        entities={data.myAuthors}
        filterFn={(item, query) =>
          item.name.toLowerCase().indexOf(query) !== -1
        }
        sortFn={(a, b) => Intl.Collator().compare(a.name, b.name)}
        onNewClick={() => history.push("/authors/new")}
      >
        {({ entities }) =>
          entities.map((e) => (
            <AuthorCard key={e.id} author={e} history={history} />
          ))
        }
      </ListView>
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default Authors;
