import React, { FC, memo } from "react";
import { History } from "history";
import { Card, H3 } from "@blueprintjs/core";
import { MyAuthors_myAuthors } from "../__generated__/types";
import { BUCKET_ROOT } from "../config/constants";
import DetailTagRow from "./DetailTagRow";
import DetailTag from "./DetailTag";
import s from "../stylesheets/AuthorCard.module.scss";

interface Props {
  author: MyAuthors_myAuthors;
  history: History;
}

const AuthorCard: FC<Props> = ({ author, history }) => {
  return (
    <Card
      elevation={2}
      key={author.id}
      className={s.root}
      interactive
      onClick={() => history.push(`/authors/${author.id}/edit`)}
    >
      <div className={s.imageWrapper}>
        <div className={s.imageMask}></div>
        <img
          src={BUCKET_ROOT + author.photoListKey}
          alt={author.name}
          className={s.image}
        />
      </div>
      <H3 className={s.name}>{author.name}</H3>
      <DetailTagRow>
        <DetailTag
          icon="bookmark"
          intent="none"
          visible={author.books.length > 0}
          content={author.books.length.toString()}
        />
        <DetailTag
          intent="primary"
          visible={!author.published}
          content="Unpublished"
        />
      </DetailTagRow>
    </Card>
  );
};

export default memo(AuthorCard);
