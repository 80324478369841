import React, { FC, useState } from "react";
import cn from "classnames";
import { RouteComponentProps, withRouter } from "react-router";
import { FormikHelpers, Formik, Form } from "formik";
import * as Yup from "yup";
import { Teams_teams, Users, Teams } from "../__generated__/types";
import DeleteTeam from "./DeleteTeam";
import s from "../stylesheets/TeamForm.module.scss";
import SelectField from "./SelectField";
import MultiSelectField from "./MultiSelectField";
import { useCustomQuery } from "../hooks/useCustomQuery";
import ErrorPage from "./ErrorPage";
import Loading from "./Loading";
import FormButtons from "./FormButtons";
import { USERS_QUERY } from "../gql/users";
import { TEAMS_QUERY } from "../gql/teams";

interface Props extends RouteComponentProps {
  initialValues: TeamValues;
  onBackClick: () => void;
  onSubmit: (
    values: TeamValues,
    formikHelpers: FormikHelpers<TeamValues>
  ) => void;
  prevTeam?: Teams_teams | null;
}

const requiredMsg = "Required";

const validationSchema = Yup.object().shape({
  agentID: Yup.number().integer().min(1).required(requiredMsg),
  assistantIDs: Yup.array().of(Yup.number().integer()),
});

const TeamForm: FC<Props> = ({
  history: { push },
  initialValues,
  onBackClick,
  onSubmit,
  prevTeam,
}) => {
  const [delTeam, setDelTeam] = useState<Teams_teams | null>(null);

  const {
    errorCode: usersErrorCode,
    loading: usersLoading,
    data: usersData,
  } = useCustomQuery<Users>({
    query: USERS_QUERY,
  });

  const {
    errorCode: teamsErrorCode,
    loading: teamsLoading,
    data: teamsData,
  } = useCustomQuery<Teams>({
    query: TEAMS_QUERY,
  });

  if (usersLoading || teamsLoading) {
    return <Loading />;
  }

  if (usersData?.users && teamsData?.teams) {
    const { users } = usersData;
    const { teams } = teamsData;
    const agentSelectChoices = [...users]
      .filter(
        (user) =>
          // either a user who is not an agent
          teams.map(({ agent }) => agent.id).indexOf(user.id) === -1 ||
          // or the previous team agent
          user.id === initialValues.agentID
      )
      .map((user) => ({ id: user.id, display: user.profile.name }))
      .sort((a, b) => Intl.Collator().compare(a.display, b.display));

    const assistantSelectChoices = [...users]
      .map((user) => ({
        id: user.id,
        display: user.profile.name,
      }))
      .sort((a, b) => Intl.Collator().compare(a.display, b.display));

    return (
      <Formik<TeamValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form autoComplete="off">
              <div className={s.row}>
                <SelectField
                  name="agentID"
                  label="Agent"
                  helperText="Should be self-explanatory"
                  items={agentSelectChoices}
                />
              </div>
              <div className={cn(s.row, s.assistants)}>
                <MultiSelectField
                  name="assistantIDs"
                  helperText="Assistants"
                  label="Assistants"
                  items={assistantSelectChoices}
                  excludeID={values.agentID}
                />
              </div>
              <FormButtons
                onBackClick={onBackClick}
                onDeleteClick={
                  prevTeam ? () => setDelTeam(prevTeam) : undefined
                }
                submitDisabled={isSubmitting}
              />
              {prevTeam && (
                <DeleteTeam
                  deletedTeam={delTeam}
                  handleClose={() => setDelTeam(null)}
                  redirectCallback={() => push(`/teams`)}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
  return <ErrorPage code={usersErrorCode || teamsErrorCode || 500} />;
};

export default withRouter(TeamForm);
