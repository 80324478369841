import React, { useState, FC } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { FormikHelpers, Formik, Form } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import {
  DeleteAuthor,
  DeleteAuthorVariables,
  MyAgents,
  MyAuthor_myAuthor,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { DELETE_AUTHOR_MUTATION } from "../gql/authors";
import {
  AUTHOR_LIST_PHOTO_PREFIX,
  AUTHOR_PROFILE_PHOTO_PREFIX,
} from "../config/constants";
import TextAreaField from "./TextAreaField";
import CheckboxField from "./CheckboxField";
import SelectField from "./SelectField";
import DeleteWithConfirm from "./DeleteWithConfirm";
import ImgPreview from "./ImgPreview";
import TextField from "./TextField";
import FileInputField from "./FileInputField";
import { useCustomQuery } from "../hooks/useCustomQuery";
import { MYAGENTS_QUERY } from "../gql/users";
import Loading from "./Loading";
import ErrorPage from "./ErrorPage";
import FormButtons from "./FormButtons";
import s from "../stylesheets/AuthorForm.module.scss";
import { deleteAuthorUpdate } from "../updateFunctions/deleteAuthorUpdate";

interface Props extends RouteComponentProps {
  prevAuthor?: MyAuthor_myAuthor | null;
  initialValues: AuthorValues;
  onSubmit: (
    values: AuthorValues,
    formikHelpers: FormikHelpers<AuthorValues>
  ) => void;
}

const requiredMsg = "Required";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(requiredMsg),
  photoListKey: Yup.string().required(requiredMsg),
  photoProfileKey: Yup.string().required(requiredMsg),
  bioEn: Yup.string().required(requiredMsg),
  bioPl: Yup.string().required(requiredMsg),
  published: Yup.bool().required(requiredMsg),
  agentID: Yup.number().integer().required(requiredMsg),
});

const AuthorForm: FC<Props> = ({
  initialValues,
  onSubmit,
  prevAuthor,
  history,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [profileUploading, setProfileUploading] = useState(false);

  const [delAuthor, setDelAuthor] = useState<MyAuthor_myAuthor | null>(null);
  const deleteAuthor = useCustomMutation<DeleteAuthor, DeleteAuthorVariables>({
    mutation: DELETE_AUTHOR_MUTATION,
    successMsg: "Author deleted successfully",
    redirectCallback: () => history.push("/authors"),
  });

  const { loading, errorCode, data } = useCustomQuery<MyAgents>({
    query: MYAGENTS_QUERY,
  });

  if (loading) {
    return <Loading />;
  }

  if (data && data.myAgents) {
    return (
      <Formik<AuthorValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form autoComplete="off">
            <div className={classNames(s.name, s.row)}>
              <TextField name="name" label="Name" helperText="Author's name" />
            </div>
            <div className={classNames(s.bio, s.row)}>
              <TextAreaField
                name="bioEn"
                label="English Biography"
                helperText="Biography in English"
                spellCheck="true"
                lang="en"
                rows={5}
              />
            </div>
            <div className={classNames(s.bio, s.row)}>
              <TextAreaField
                name="bioPl"
                label="Polish Biography"
                helperText="Biography in Polish"
                spellCheck="true"
                lang="pl"
                rows={5}
              />
            </div>
            <div className={classNames(s.row, s.photo)}>
              <FileInputField
                name="photoListKey"
                label="List Photo"
                fileType="image/*"
                helperText="Photo for the list of authors"
                uploadPrefix={AUTHOR_LIST_PHOTO_PREFIX}
                setUploading={setIsUploading}
                acl="public-read"
                preview={
                  <ImgPreview
                    imgKey={values.photoListKey}
                    width={200}
                    height={143}
                  />
                }
              />
            </div>
            <div className={classNames(s.row, s.photo)}>
              <FileInputField
                name="photoProfileKey"
                label="Profile Photo"
                fileType="image/*"
                helperText="Photo for the profile of this author"
                uploadPrefix={AUTHOR_PROFILE_PHOTO_PREFIX}
                setUploading={setProfileUploading}
                acl="public-read"
                preview={
                  <ImgPreview
                    imgKey={values.photoProfileKey}
                    width={150}
                    height={200}
                  />
                }
              />
            </div>
            <div className={s.row}>
              <SelectField
                name="agentID"
                label="Agent"
                helperText="Should be self-explanatory"
                items={[...data.myAgents]
                  .map((agent) => ({
                    id: agent.id,
                    display: agent.profile.name,
                  }))
                  .sort((a, b) =>
                    Intl.Collator().compare(a.display, b.display)
                  )}
              />
            </div>
            <div className={s.row}>
              <CheckboxField name="published" label="Published" />
            </div>
            <FormButtons
              onBackClick={() => history.push("/authors")}
              onDeleteClick={
                prevAuthor ? () => setDelAuthor(prevAuthor) : undefined
              }
              submitDisabled={isSubmitting || isUploading || profileUploading}
            />
            <DeleteWithConfirm
              entity={delAuthor}
              handleClose={() => setDelAuthor(null)}
              handleSubmit={async () => {
                if (delAuthor !== null) {
                  try {
                    await deleteAuthor({
                      variables: { id: delAuthor.id },
                      update: deleteAuthorUpdate,
                    });
                  } catch (error) {
                    setDelAuthor(null);
                  }
                }
              }}
            />
          </Form>
        )}
      </Formik>
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default withRouter(AuthorForm);
