import React, { FC } from "react";
import { NonIdealState, Button } from "@blueprintjs/core";
import { RouteComponentProps, withRouter } from "react-router";
import s from "../stylesheets/ErrorPage.module.scss";
import { BACKEND_DOMAIN, FRONTEND_DOMAIN } from "../config/constants";

interface Props extends RouteComponentProps {
  code?: 400 | 401 | 403 | 404 | 415 | 429 | 500 | 501;
}

const errorCodes = {
  400: "Bad Request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not Found",
  415: "Unsupported Media Type",
  429: "Too Many Requests",
  500: "Internal Server Error",
  501: "Not Implemented",
};

const ErrorPage: FC<Props> = ({ code = 404, history }) => {
  return (
    <div className={s.root}>
      <NonIdealState
        icon="error"
        title={`Error ${code}`}
        description={errorCodes[code]}
        action={
          code === 401 ? (
            <Button
              onClick={() =>
                window.location.assign(
                  BACKEND_DOMAIN +
                    "/login?redirect=" +
                    encodeURI(FRONTEND_DOMAIN + history.location.pathname)
                )
              }
            >
              Log in again
            </Button>
          ) : (
            <Button onClick={() => history.push("/")}>
              Return to the main page
            </Button>
          )
        }
        className={s.nis}
      />
    </div>
  );
};

export default withRouter(ErrorPage);
