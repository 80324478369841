import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import {
  UpdateLanguage,
  UpdateLanguageVariables,
  Language,
  LanguageVariables,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import LanguageForm from "./LanguageForm";
import ErrorPage from "./ErrorPage";
import FormView from "./FormView";
import { UPDATE_LANGUAGE_MUTATION, LANGUAGE_QUERY } from "../gql/languages";
import { useCustomQuery } from "../hooks/useCustomQuery";
import Loading from "./Loading";

type Props = RouteComponentProps<{ langID: string }>;

const LanguageEdit: FC<Props> = ({
  match: {
    params: { langID },
  },
  history: { push },
}) => {
  const updateLanguage = useCustomMutation<
    UpdateLanguage,
    UpdateLanguageVariables
  >({
    mutation: UPDATE_LANGUAGE_MUTATION,
    successMsg: "Language updated successfully",
    redirectCallback: () => push("/languages"),
  });

  const { loading, data, errorCode } = useCustomQuery<
    Language,
    LanguageVariables
  >({
    query: LANGUAGE_QUERY,
    variables: { id: langID },
  });

  if (loading) {
    return <Loading />;
  }

  if (data && data.language) {
    const { language } = data;
    const initialValues: LanguageValues = {
      nameEn: language.nameEn,
      namePl: language.namePl,
    };

    return (
      <FormView
        title={language.nameEn}
        main={
          <LanguageForm
            prevLanguage={language}
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await updateLanguage({
                  variables: { id: langID, data: values },
                });
              } catch (error) {
                setSubmitting(false);
              }
            }}
            onBackClick={() => push("/languages")}
          />
        }
      />
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default LanguageEdit;
