import { MutationUpdaterFn } from "@apollo/client";
import { CreateTeam, Teams } from "../__generated__/types";
import { updateCache } from "../utils/updateCache";
import { TEAMS_QUERY } from "../gql/teams";

export const createTeamUpdate: MutationUpdaterFn<CreateTeam> = (
  cache,
  result
) => {
  updateCache<Teams, {}, CreateTeam>({
    cache,
    result,
    cachedQuery: { query: TEAMS_QUERY },
    update: ({ teams }, { createTeam }) => ({
      teams: teams.concat([createTeam]),
    }),
  });
};
