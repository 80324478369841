import { MutationUpdaterFn } from "@apollo/client";
import { CreateLanguage, Languages } from "../__generated__/types";
import { updateCache } from "../utils/updateCache";
import { LANGUAGES_QUERY } from "../gql/languages";

export const createLanguageUpdate: MutationUpdaterFn<CreateLanguage> = (
  cache,
  result
) => {
  updateCache<Languages, {}, CreateLanguage>({
    cache,
    result,
    cachedQuery: { query: LANGUAGES_QUERY },
    update: ({ languages }, { createLanguage }) => ({
      languages: languages.concat([createLanguage]),
    }),
  });
};
