const formatDate = (date: string): string => {
  const parsedDate = new Date(date);
  const year = parsedDate.getFullYear();
  const month = parsedDate.getMonth();
  const day = parsedDate.getDate();
  return `${year}-${month + 1 < 10 ? "0" : ""}${month + 1}-${
    day < 10 ? "0" : ""
  }${day}`;
};

export default formatDate;
