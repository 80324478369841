import React from "react";
import { History } from "history";
import { Card, H4 } from "@blueprintjs/core";
import { Territories_territories } from "../__generated__/types";
import s from "../stylesheets/TerritoryCard.module.scss";

interface Props {
  territory: Territories_territories;
  history: History;
}

const TerritoryCard: React.FC<Props> = ({ territory, history }) => {
  return (
    <Card
      elevation={2}
      className={s.card}
      interactive
      onClick={() => history.push(`/territories/${territory.id}/edit`)}
    >
      <React.Fragment>
        <H4 className={s.englishName}>{territory.nameEn}</H4>
        <p className={s.polishName}>{territory.namePl}</p>
      </React.Fragment>
    </Card>
  );
};

export default TerritoryCard;
