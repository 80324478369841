import { MutationUpdaterFn } from "@apollo/client";
import { MYAUTHORS_QUERY, MYAUTHOR_QUERY } from "../gql/authors";
import { updateCache } from "../utils/updateCache";
import {
  DeleteBook,
  MyAuthor,
  MyAuthors,
  MyAuthorVariables,
} from "../__generated__/types";

export const deleteBookUpdate = (
  deletedBookAuthorIDs: string[]
): MutationUpdaterFn<DeleteBook> => (cache, result) => {
  // MYAUTHORS_QUERY
  updateCache<MyAuthors, unknown, DeleteBook>({
    cache,
    result,
    cachedQuery: { query: MYAUTHORS_QUERY },
    update: ({ myAuthors }, { deleteBook: { id } }) => ({
      myAuthors: myAuthors.map((cachedAuthor) =>
        deletedBookAuthorIDs.includes(cachedAuthor.id)
          ? {
              ...cachedAuthor,
              books: cachedAuthor.books.filter((b) => b.id !== id),
            }
          : cachedAuthor
      ),
    }),
  });

  if (result.data?.deleteBook) {
    const { id, __typename } = result.data.deleteBook;

    // MYAUTHOR_QUERY (one for each author of the book)
    deletedBookAuthorIDs.forEach((deletedBookAuthorID) => {
      updateCache<MyAuthor, MyAuthorVariables, DeleteBook>({
        cache,
        result,
        cachedQuery: {
          query: MYAUTHOR_QUERY,
          variables: { id: deletedBookAuthorID },
        },
        update: ({ myAuthor }, { deleteBook: { id } }) => ({
          myAuthor: {
            ...myAuthor,
            books: myAuthor.books.filter((b) => b.id !== id),
          },
        }),
      });
    });

    // evict from cache
    cache.evict({ id: `${__typename}:${id}` });
    cache.gc();
  }
};
