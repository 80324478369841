import React, { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  MyClient,
  MyClientVariables,
  UpdateClient,
  UpdateClientVariables,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { useCustomQuery } from "../hooks/useCustomQuery";
import { MYCLIENT_QUERY, UPDATE_CLIENT_MUTATION } from "../gql/clients";
import CatalogSidebar from "./CatalogSidebar";
import ClientForm from "./ClientForm";
import ErrorPage from "./ErrorPage";
import FormView from "./FormView";
import Loading from "./Loading";

const ClientEdit: FC<RouteComponentProps<{ clientID: string }>> = ({
  history,
  match,
}) => {
  const updateClient = useCustomMutation<UpdateClient, UpdateClientVariables>({
    mutation: UPDATE_CLIENT_MUTATION,
    successMsg: "Client Updated Successfully",
    redirectCallback: () => history.push("/clients"),
  });

  const { errorCode, loading, data } = useCustomQuery<
    MyClient,
    MyClientVariables
  >({
    query: MYCLIENT_QUERY,
    variables: { id: match.params.clientID },
  });

  if (loading) {
    return <Loading />;
  }

  if (data && data.myClient) {
    const { myClient } = data;
    const initialValues: ClientValues = {
      name: myClient.name,
      descriptionEn: myClient.descriptionEn,
      descriptionPl: myClient.descriptionPl,
      letters: myClient.letters,
      website: myClient.website ? myClient.website : "",
      published: myClient.published,
      agentID: myClient.agent.id,
      territoryIDs: myClient.territories.map((t) => t.id),
    };

    return (
      <FormView
        title={myClient.name}
        main={
          <ClientForm
            prevClient={myClient}
            initialValues={initialValues}
            onSubmit={async (data, { setSubmitting }) => {
              try {
                await updateClient({
                  variables: { id: myClient.id, data },
                });
              } catch (error) {
                setSubmitting(false);
              }
            }}
          />
        }
        sidebar={
          <CatalogSidebar
            catalogs={myClient.catalogs}
            clientID={myClient.id}
            history={history}
          />
        }
      />
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default ClientEdit;
