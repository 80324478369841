import React, { FC, memo, MouseEvent } from "react";
import { History } from "history";
import { Card, H5, Button } from "@blueprintjs/core";
import { MyClient_myClient_catalogs } from "../__generated__/types";
import daysSinceNow from "../utils/daysSinceNow";
import formatDate from "../utils/formatDate";
import DetailTag from "./DetailTag";
import DetailTagRow from "./DetailTagRow";
import s from "../stylesheets/CatalogCard.module.scss";

interface Props {
  catalog: MyClient_myClient_catalogs;
  clientID: string;
  deleteHandler: (
    event: MouseEvent<HTMLElement>,
    catalog: MyClient_myClient_catalogs
  ) => void;
  history: History;
}

const CatalogCard: FC<Props> = ({
  catalog,
  history,
  clientID,
  deleteHandler,
}) => {
  return (
    <Card
      elevation={2}
      className={s.card}
      interactive
      onClick={() =>
        history.push(`/catalog/${catalog.id}/edit`, {
          prevClientID: clientID,
        })
      }
    >
      <div className={s.header}>
        <H5 className={s.name}>{catalog.name}</H5>
        <Button
          small
          minimal
          className={s.close}
          icon="cross"
          onClick={(e: MouseEvent<HTMLElement>) => deleteHandler(e, catalog)}
        />
      </div>
      <DetailTagRow>
        <DetailTag
          visible
          intent="none"
          icon={catalog.fileKey !== null ? "document" : "link"}
          content=""
        />
        <DetailTag
          visible
          intent="none"
          content={formatDate(catalog.dateAdded)}
        />
        <DetailTag
          visible={daysSinceNow(catalog.dateAdded) > 365}
          intent="danger"
          icon="outdated"
          content="Outdated?"
        />
      </DetailTagRow>
    </Card>
  );
};

export default memo(CatalogCard);
