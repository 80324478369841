import React from "react";
import { History } from "history";
import { Card, H3, H5 } from "@blueprintjs/core";
import { Languages_languages } from "../__generated__/types";
import s from "../stylesheets/LanguageCard.module.scss";

interface Props {
  language: Languages_languages;
  history: History;
}

const LanguageCard: React.FC<Props> = ({ language, history }) => {
  return (
    <Card
      elevation={2}
      className={s.card}
      interactive
      onClick={() => history.push(`/language/${language.id}/edit`)}
    >
      <div>
        <H3 className={s.nameEn}>{language.nameEn}</H3>
        <H5 className={s.namePl}>{language.namePl}</H5>
      </div>
    </Card>
  );
};

export default LanguageCard;
