import React from "react";
import { LoginContext } from "../context/loginContext";
import { ME_QUERY } from "../gql/users";
import { Me } from "../__generated__/types";
import { useQuery } from "@apollo/client";
import { useLoginLogout } from "../hooks/useLoginLogout";

const AssureLogin: React.FC = ({ children }) => {
  const [{ status }] = React.useContext(LoginContext);
  const { logout } = useLoginLogout();
  switch (status) {
    case "LOGGED_IN":
      return <>{children}</>;
    case "LOGGING_OUT":
      logout();
      return null;
    default:
      return <FetchOrLogin />;
  }
};

const FetchOrLogin: React.FC = () => {
  const [, dispatch] = React.useContext(LoginContext);
  const { login } = useLoginLogout();
  const { data, error } = useQuery<Me>(ME_QUERY, {
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (data?.me) {
      dispatch({ type: "LOGIN", payload: data });
    }
  }, [data, dispatch]);

  if (error) {
    if (error.message.endsWith("unauthorized")) {
      login();
    } else {
      throw new Error(error.message);
    }
  }

  return <div>Logging in...</div>;
};

export default AssureLogin;
