import { gql } from "@apollo/client";

export const CATALOG_ATTRIBUTES = gql`
  fragment CatalogAttributes on Catalog {
    id
    name
    url
    fileKey
    link
    dateAdded
  }
`;

export const MYCATALOG_QUERY = gql`
  query MyCatalog($id: ID!) {
    myCatalog(id: $id) {
      ...CatalogAttributes
      client {
        id
      }
    }
  }
  ${CATALOG_ATTRIBUTES}
`;

export const CREATE_CATALOG_MUTATION = gql`
  mutation CreateCatalog($data: CatalogInput!) {
    createCatalog(data: $data) {
      ...CatalogAttributes
      client {
        id
      }
    }
  }
  ${CATALOG_ATTRIBUTES}
`;

export const DELETE_CATALOG_MUTATION = gql`
  mutation DeleteCatalog($id: ID!) {
    deleteCatalog(id: $id) {
      id
      client {
        id
      }
    }
  }
`;

export const UPDATE_CATALOG_MUTATION = gql`
  mutation UpdateCatalog($id: ID!, $data: CatalogInput!) {
    updateCatalog(id: $id, data: $data) {
      ...CatalogAttributes
      client {
        id
      }
    }
  }
  ${CATALOG_ATTRIBUTES}
`;
