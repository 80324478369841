import { MutationUpdaterFn } from "@apollo/client";
import {
  DeleteClient,
  MyClient_myClient_catalogs,
} from "../__generated__/types";

export const deleteClientUpdate = (
  deletedCatalogs: MyClient_myClient_catalogs[]
): MutationUpdaterFn<DeleteClient> => (cache, result) => {
  if (result.data?.deleteClient) {
    const { id, __typename } = result.data.deleteClient;
    cache.evict({ id: `${__typename}:${id}` });
    deletedCatalogs.forEach(({ id, __typename }) => {
      cache.evict({ id: `${__typename}:${id}` });
    });
    cache.gc();
  }
};
