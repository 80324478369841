import React, { FC, useState, FormEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import classNames from "classnames";
import { FormikHelpers, Formik, Form, FormikErrors } from "formik";
import { RadioGroup, Radio } from "@blueprintjs/core";
import { MyCatalog_myCatalog, MyClients } from "../__generated__/types";
import { CATALOG_PREFIX } from "../config/constants";
import DateInputField from "./DateInputField";
import DeleteCatalog from "./DeleteCatalog";
import FileInputField from "./FileInputField";
import TextField from "./TextField";
import s from "../stylesheets/CatalogForm.module.scss";
import ErrorPage from "./ErrorPage";
import Loading from "./Loading";
import { useCustomQuery } from "../hooks/useCustomQuery";
import { MYCLIENTS_QUERY } from "../gql/clients";
import SelectField from "./SelectField";
import FormButtons from "./FormButtons";

interface Props extends RouteComponentProps {
  initialValues: CatalogValues;
  onBackClick: () => void;
  onSubmit: (
    values: CatalogValues,
    formikHelpers: FormikHelpers<CatalogValues>
  ) => void;
  prevCatalog?: MyCatalog_myCatalog | null;
  prevClientID?: string;
}

const CatalogForm: FC<Props> = ({
  prevClientID,
  history,
  initialValues,
  onBackClick,
  onSubmit,
  prevCatalog,
}) => {
  const [delCat, setDelCat] = useState<MyCatalog_myCatalog | null>(null);
  const [fileOrLink, setFileOrLink] = useState<string>(
    initialValues.url ? "link" : "file"
  );
  const [isUploading, setUploading] = useState(false);

  const { errorCode, loading, data } = useCustomQuery<MyClients>({
    query: MYCLIENTS_QUERY,
  });

  if (loading) {
    return <Loading />;
  }

  if (data && data.myClients) {
    return (
      <Formik<CatalogValues>
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          // remove the unneeded value before saving to the DB
          if (fileOrLink === "file") {
            values.url = null;
          } else if (fileOrLink === "link") {
            values.fileKey = null;
          }
          onSubmit(values, actions);
        }}
        validate={(values) => {
          const errors: FormikErrors<CatalogValues> = {};
          const requiredMsg = "Required";
          if (!values.clientID) {
            errors.clientID = requiredMsg;
          }
          if (!values.name) {
            errors.name = requiredMsg;
          }
          if (!values.dateAdded) {
            errors.dateAdded = requiredMsg;
          }
          if (!values.fileKey && fileOrLink === "file") {
            errors.fileKey = requiredMsg;
          }
          if (!values.url && fileOrLink === "link") {
            errors.url = requiredMsg;
          }
          return errors;
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <div className={classNames(s.row)}>
              <SelectField
                name="clientID"
                label="Client"
                helperText="Should be self-explanatory"
                items={data.myClients
                  .map((client) => ({
                    id: client.id,
                    display: client.name,
                  }))
                  .sort((a, b) =>
                    Intl.Collator().compare(a.display, b.display)
                  )}
              />
            </div>
            <div className={classNames(s.row, s.name)}>
              <TextField
                name="name"
                label="Catalog name"
                helperText="Descriptive name of the catalog"
              />
            </div>
            <div>
              <RadioGroup
                label="File or link?"
                onChange={(e: FormEvent<HTMLInputElement>) =>
                  setFileOrLink(e.currentTarget.value)
                }
                selectedValue={fileOrLink}
              >
                <Radio large label="File" value="file" />
                <Radio large label="Link" value="link" />
              </RadioGroup>
            </div>
            {fileOrLink === "file" && (
              <div className={classNames(s.row, s.fileInput)}>
                <FileInputField
                  name="fileKey"
                  helperText="Choose and upload a file in PDF format"
                  fileType="application/pdf"
                  setUploading={setUploading}
                  uploadPrefix={CATALOG_PREFIX}
                />
              </div>
            )}
            {fileOrLink === "link" && (
              <div className={classNames(s.row)}>
                <TextField
                  name="url"
                  label=""
                  placeholder="Enter a URL..."
                  helperText="External link to the catalog"
                />
              </div>
            )}
            <div className={classNames(s.row)}>
              <DateInputField
                name="dateAdded"
                label="Date"
                helperText="Determines the order of catalogs on client profile"
              />
            </div>
            <FormButtons
              onBackClick={onBackClick}
              onDeleteClick={
                prevCatalog && prevClientID
                  ? () => setDelCat(prevCatalog)
                  : undefined
              }
              submitDisabled={isSubmitting || isUploading}
            />
            {prevCatalog && prevClientID && (
              <DeleteCatalog
                deletedCatalog={delCat}
                handleClose={() => setDelCat(null)}
                redirectCallback={() =>
                  history.push(`/clients/${prevClientID}/edit`)
                }
                clientID={prevClientID}
              />
            )}
          </Form>
        )}
      </Formik>
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default withRouter(CatalogForm);
