import React, { FC, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import classNames from "classnames";
import { FormikHelpers, Formik, Form } from "formik";
import * as Yup from "yup";
import { Languages_languages } from "../__generated__/types";
import TextField from "./TextField";
import FormButtons from "./FormButtons";
import DeleteLanguage from "./DeleteLanguage";
import s from "../stylesheets/LanguageForm.module.scss";

interface Props extends RouteComponentProps {
  initialValues: LanguageValues;
  onBackClick: () => void;
  onSubmit: (
    values: LanguageValues,
    formikHelpers: FormikHelpers<LanguageValues>
  ) => void;
  prevLanguage?: Languages_languages | null;
}

const requiredMsg = "Required";

const validationSchema = Yup.object().shape({
  nameEn: Yup.string().required(requiredMsg),
  namePl: Yup.string().required(requiredMsg),
});

const LanguageForm: FC<Props> = ({
  history: { push },
  initialValues,
  onBackClick,
  onSubmit,
  prevLanguage,
}) => {
  const [delLang, setDelLang] = useState<Languages_languages | null>(null);

  return (
    <Formik<LanguageValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <div className={classNames(s.row, s.name)}>
            <TextField
              name="nameEn"
              label="English name"
              helperText="Name of the language in English"
            />
          </div>
          <div className={classNames(s.row, s.name)}>
            <TextField
              name="namePl"
              label="Polish name"
              helperText="Name of the language in Polish"
            />
          </div>
          <FormButtons
            onBackClick={onBackClick}
            onDeleteClick={
              prevLanguage ? () => setDelLang(prevLanguage) : undefined
            }
            submitDisabled={isSubmitting}
          />
          {prevLanguage && (
            <DeleteLanguage
              deletedLanguage={delLang}
              handleClose={() => setDelLang(null)}
              redirectCallback={() => push(`/languages`)}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(LanguageForm);
