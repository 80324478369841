import { gql } from "@apollo/client";
import { USER_ATTRIBUTES } from "./users";

const TEAM_ATTRIBUTES = gql`
  fragment TeamAttributes on Team {
    id
    agent {
      ...UserAttributes
    }
    assistants {
      ...UserAttributes
    }
  }
  ${USER_ATTRIBUTES}
`;

export const TEAM_QUERY = gql`
  query Team($id: ID!) {
    team(id: $id) {
      ...TeamAttributes
    }
  }
  ${TEAM_ATTRIBUTES}
`;

export const TEAMS_QUERY = gql`
  query Teams {
    teams {
      ...TeamAttributes
    }
  }
  ${TEAM_ATTRIBUTES}
`;

export const CREATE_TEAM_MUTATION = gql`
  mutation CreateTeam($data: TeamInput!) {
    createTeam(data: $data) {
      ...TeamAttributes
    }
  }
  ${TEAM_ATTRIBUTES}
`;

export const DELETE_TEAM_MUTATION = gql`
  mutation DeleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      id
    }
  }
`;

export const UPDATE_TEAM_MUTATION = gql`
  mutation UpdateTeam($id: ID!, $data: TeamInput!) {
    updateTeam(id: $id, data: $data) {
      ...TeamAttributes
    }
  }
  ${TEAM_ATTRIBUTES}
`;
