import { MutationUpdaterFn } from "@apollo/client";
import { UpdateBook, MyAuthors } from "../__generated__/types";
import { updateCache } from "../utils/updateCache";
import { MYAUTHORS_QUERY } from "../gql/authors";

export const updateBookUpdate = (
  prevAuthorIDs: string[],
  nextAuthorIDs: string[]
): MutationUpdaterFn<UpdateBook> => (cache, result) => {
  updateCache<MyAuthors, {}, UpdateBook>({
    cache,
    result,
    cachedQuery: { query: MYAUTHORS_QUERY },
    update: ({ myAuthors }, { updateBook }) => {
      // if in prevAuthors and in nextAuthors -- no change
      // if in prevAuthors and not in nextAuthors -- author was removed from the book
      // if not in prevAuthors and in nextAuthors -- author was added to the book
      return {
        myAuthors: myAuthors.map((cachedAuthor) => {
          const { id } = cachedAuthor;
          if (prevAuthorIDs.includes(id) && !nextAuthorIDs.includes(id)) {
            return {
              ...cachedAuthor,
              books: cachedAuthor.books.filter(
                ({ id }) => id !== updateBook.id
              ),
            };
          }
          if (!prevAuthorIDs.includes(id) && nextAuthorIDs.includes(id)) {
            return {
              ...cachedAuthor,
              books: cachedAuthor.books.concat([
                {
                  id: updateBook.id,
                  __typename: updateBook.__typename,
                },
              ]),
            };
          }
          return cachedAuthor;
        }),
      };
    },
  });
};
