import { MutationUpdaterFn } from "@apollo/client";
import {
  MyAuthors,
  CreateBook,
  MyAuthor,
  MyAuthorVariables,
} from "../__generated__/types";
import { updateCache } from "../utils/updateCache";
import { MYAUTHORS_QUERY, MYAUTHOR_QUERY } from "../gql/authors";

export const createBookUpdate: MutationUpdaterFn<CreateBook> = (
  cache,
  result
) => {
  updateCache<MyAuthors, {}, CreateBook>({
    cache,
    result,
    cachedQuery: { query: MYAUTHORS_QUERY },
    update: ({ myAuthors }, { createBook: { id, __typename, authors } }) => ({
      myAuthors: myAuthors.map((author) =>
        authors.some((a) => a.id === author.id)
          ? {
              ...author,
              books: alreadyIncluded(author.books, id)
                ? author.books
                : author.books.concat([{ id, __typename }]),
            }
          : author
      ),
    }),
  });

  if (result.data?.createBook) {
    result.data.createBook.authors.forEach(({ id }) => {
      updateCache<MyAuthor, MyAuthorVariables, CreateBook>({
        cache,
        result,
        cachedQuery: {
          query: MYAUTHOR_QUERY,
          variables: { id },
        },
        update: ({ myAuthor }, { createBook }) => ({
          myAuthor: {
            ...myAuthor,
            books: alreadyIncluded(myAuthor.books, createBook.id)
              ? myAuthor.books
              : myAuthor.books.concat([createBook]),
          },
        }),
      });
    });
  }
};

// alreadyIncluded prevents adding duplicate items
function alreadyIncluded(current: { id: string }[], newID: string) {
  return current.some((b) => b.id === newID);
}
