import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import {
  UpdateTerritory,
  UpdateTerritoryVariables,
  Territory,
  TerritoryVariables,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { TERRITORY_QUERY, UPDATE_TERRITORY_MUTATION } from "../gql/territories";
import TerritoryForm from "./TerritoryForm";
import FormView from "./FormView";
import ErrorPage from "./ErrorPage";
import { useCustomQuery } from "../hooks/useCustomQuery";
import Loading from "./Loading";

const TerritoryEdit: FC<RouteComponentProps<{ territoryID: string }>> = ({
  match,
  history: { push },
}) => {
  const updateTerritory = useCustomMutation<
    UpdateTerritory,
    UpdateTerritoryVariables
  >({
    mutation: UPDATE_TERRITORY_MUTATION,
    successMsg: "Territory updated successfully",
    redirectCallback: () => push("/territories"),
  });

  const { loading, data, errorCode } = useCustomQuery<
    Territory,
    TerritoryVariables
  >({
    query: TERRITORY_QUERY,
    variables: { id: match.params.territoryID },
  });

  if (loading) {
    return <Loading />;
  }

  if (data?.territory) {
    const { territory } = data;
    const initialValues: TerritoryValues = {
      iso3: territory.iso3,
      nameEn: territory.nameEn,
      namePl: territory.namePl,
      languageEn: territory.languageEn,
      languagePl: territory.languagePl,
      mailchimpID: territory.mailchimpID,
    };
    return (
      <FormView
        title="Edit Territory"
        main={
          <TerritoryForm
            prevTerritory={territory}
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await updateTerritory({
                  variables: {
                    id: territory.id,
                    data: { ...values, iso3: values.iso3.toUpperCase() },
                  },
                });
              } catch (error) {
                setSubmitting(false);
              }
            }}
            onBackClick={() => push("/territories")}
          />
        }
      />
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default TerritoryEdit;
