import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import {
  CreateLanguage,
  CreateLanguageVariables,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { CREATE_LANGUAGE_MUTATION } from "../gql/languages";
import LanguageForm from "./LanguageForm";
import FormView from "./FormView";
import { createLanguageUpdate } from "../updateFunctions/createLanguageUpdate";

const LanguageNew: FC<RouteComponentProps> = ({ history: { push } }) => {
  const createLanguage = useCustomMutation<
    CreateLanguage,
    CreateLanguageVariables
  >({
    mutation: CREATE_LANGUAGE_MUTATION,
    successMsg: "Language created successfully",
    redirectCallback: () => push("/languages"),
  });

  const initialValues: LanguageValues = {
    nameEn: "",
    namePl: "",
  };

  return (
    <FormView
      title="New Language"
      main={
        <LanguageForm
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await createLanguage({
                variables: { data: values },
                update: createLanguageUpdate,
              });
            } catch (error) {
              setSubmitting(false);
            }
          }}
          onBackClick={() => push("/languages")}
        />
      }
    />
  );
};

export default LanguageNew;
