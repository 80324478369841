import React, { ReactElement } from "react";
import {
  Formik,
  FormikErrors,
  Form,
  Field,
  FieldProps,
  FormikHelpers,
} from "formik";
import { Dialog, Classes, InputGroup, Tag, Button } from "@blueprintjs/core";

interface Props<T> {
  entity: T | null;
  handleClose: () => void;
  handleSubmit: (
    values: ConfirmValues,
    formikHelpers: FormikHelpers<ConfirmValues>
  ) => void;
}

interface EntityWithName {
  id: string;
  name: string;
  __typename: "Author" | "Client";
}

interface ConfirmValues {
  confirm: string;
}

const DeleteWithConfirm = <T extends EntityWithName>({
  entity,
  handleClose,
  handleSubmit,
}: Props<T>): ReactElement<Props<T>> | null => {
  return entity !== null ? (
    <Dialog
      title="Confirmation required"
      onClose={handleClose}
      isOpen
      canEscapeKeyClose
      canOutsideClickClose
      isCloseButtonShown
      enforceFocus
      usePortal
    >
      <div>
        <Formik<ConfirmValues>
          initialValues={{ confirm: "" }}
          onSubmit={handleSubmit}
          validate={(values) => {
            const errors: FormikErrors<ConfirmValues> = {};
            if (values.confirm !== entity.name) {
              errors.confirm = "No match";
            }
            return errors;
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className={Classes.DIALOG_BODY}>
                <p>
                  <span>Really delete the </span>
                  <strong>{entity.name} </strong>
                  <span>profile?</span>
                </p>
                <p>
                  This operation will also delete all&nbsp;
                  {`${entity.__typename === "Author" ? "books" : "catalogs"}`}
                  &nbsp; associated with the profile. Are you sure you
                  don&apos;t want to unpublish the profile instead?
                </p>
                <p>
                  To confirm please type the exact name of the
                  {`${entity.__typename === "Author" ? " author" : " client"}`}
                  &nbsp; below before pressing Delete.
                </p>
                <Field name="confirm">
                  {({ field }: FieldProps) => (
                    <div>
                      <InputGroup
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        autoComplete="false"
                        rightElement={
                          touched.confirm && errors.confirm ? (
                            <Tag minimal intent="danger">
                              No Match
                            </Tag>
                          ) : undefined
                        }
                      />
                    </div>
                  )}
                </Field>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button type="button" intent="none" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" intent="danger">
                    Delete
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  ) : null;
};

export default DeleteWithConfirm;
