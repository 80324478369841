import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Card, H3, Icon, IconName } from "@blueprintjs/core";
import s from "../stylesheets/HomeCard.module.scss";

interface Props {
  to: string;
  title: string;
  desc: string;
  icon: IconName;
}

const HomeCard: FC<Props> = ({ to, title, desc, icon }) => {
  return (
    <Link to={to} className={s.link}>
      <Card interactive elevation={2} className={s.card}>
        <div>
          <H3>{title}</H3>
          <p>{desc}</p>
        </div>
        <Icon icon={icon} iconSize={64} className={s.icon} />
      </Card>
    </Link>
  );
};

export default HomeCard;
