import { useState } from "react";
import { useMutation } from "@apollo/client";
import { PresignURL, PresignURLVariables } from "../__generated__/types";
import { PRESIGN_URL_MUTATION } from "../gql/presignURL";
import { uploadWithProgress } from "../services/uploader";
import { safeUnique } from "../utils/fileName";

//
type Uploader = (
  url: string,
  file: File,
  headers: { [key: string]: string },
  progressCallback: (progress: number) => void
) => Promise<void>;

export function useFileUpload(
  prefix: string,
  acl: Acl,
  uploader: Uploader = uploadWithProgress
): {
  upload: (file: File) => Promise<string>;
  progress: number;
  isUploading: boolean;
} {
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [presign] = useMutation<PresignURL, PresignURLVariables>(
    PRESIGN_URL_MUTATION
  );

  async function getPresignedURL(key: string, filetype: string) {
    const res = await presign({ variables: { data: { acl, filetype, key } } });
    if (!res || !res.data) {
      if (res && res.errors) logError(res.errors);
      return;
    }
    return res.data.presignURL;
  }

  async function upload(file: File) {
    if (progress !== 0) setProgress(0);
    const key = prefix + safeUnique(file.name);
    const url = await getPresignedURL(key, file.type);
    if (!url) {
      throw new Error("Error presigning upload URL");
    }
    const headers = {
      "Content-Type": file.type,
      "x-amz-acl": acl,
    };
    setIsUploading(true);
    try {
      await uploader(url, file, headers, setProgress);
      setIsUploading(false);
    } catch (e) {
      logError(e);
      setIsUploading(false);
      throw new Error("Error uploading file");
    }
    return key;
  }

  return { upload, isUploading, progress };
}

function logError(e: unknown): void {
  if (process.env.NODE_ENV === "development") {
    JSON.stringify(e, undefined, 2);
  }
}
