import { MutationUpdaterFn } from "@apollo/client";
import { UpdateCatalog, MyClients } from "../__generated__/types";
import { updateCache } from "../utils/updateCache";
import { MYCLIENTS_QUERY } from "../gql/clients";

export const updateCatalogUpdate = (
  prevClientID: string
): MutationUpdaterFn<UpdateCatalog> => (cache, result) => {
  updateCache<MyClients, {}, UpdateCatalog>({
    cache,
    result,
    cachedQuery: { query: MYCLIENTS_QUERY },
    update: ({ myClients }, { updateCatalog }) => {
      // if prevClient === nextClient -- no change
      // else --
      // 1. remove catalog from prevClient's catalogs
      // 2. add catalog to nextClient's catalogs

      const nextClientID = updateCatalog.client.id;

      if (prevClientID === nextClientID) {
        return { myClients };
      }

      return {
        myClients: myClients.map((cachedClient) => {
          const { id } = cachedClient;
          if (id === prevClientID) {
            return {
              ...cachedClient,
              catalogs: cachedClient.catalogs.filter(
                (c) => c.id !== updateCatalog.id
              ),
            };
          }
          if (id === nextClientID) {
            return {
              ...cachedClient,
              catalogs: cachedClient.catalogs.concat([updateCatalog]),
            };
          }
          return cachedClient;
        }),
      };
    },
  });
};
