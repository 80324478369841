import { gql } from "@apollo/client";
import { PROFILE_NAME } from "./profiles";

export const USER_ATTRIBUTES = gql`
  fragment UserAttributes on User {
    id
    profile {
      ...ProfileName
    }
  }
  ${PROFILE_NAME}
`;

export const USER_ADMIN_ATTRIBUTES = gql`
  fragment UserAdminAttributes on User {
    id
    admin
    email
    profile {
      ...ProfileName
    }
  }
  ${PROFILE_NAME}
`;

export const ME_QUERY = gql`
  query Me {
    me {
      id
      admin
      profile {
        id
        name
        photo
      }
    }
  }
`;

export const MYAGENTS_QUERY = gql`
  query MyAgents {
    myAgents {
      ...UserAttributes
    }
  }
  ${USER_ATTRIBUTES}
`;

export const USER_QUERY = gql`
  query User($id: ID!) {
    user(id: $id) {
      ...UserAdminAttributes
    }
  }
  ${USER_ADMIN_ATTRIBUTES}
`;

export const USERS_QUERY = gql`
  query Users {
    users {
      ...UserAdminAttributes
    }
  }
  ${USER_ADMIN_ATTRIBUTES}
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($data: UserInput!) {
    createUser(data: $data) {
      ...UserAdminAttributes
    }
  }
  ${USER_ADMIN_ATTRIBUTES}
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: ID!, $data: UserInput!) {
    updateUser(id: $id, data: $data) {
      ...UserAdminAttributes
    }
  }
  ${USER_ADMIN_ATTRIBUTES}
`;
