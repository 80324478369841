import React, { FC } from "react";
import classNames from "classnames";
import { H2, Card } from "@blueprintjs/core";
import s from "../stylesheets/FormView.module.scss";

interface Props {
  title: string;
  main: JSX.Element;
  sidebar?: JSX.Element;
}

const FormView: FC<Props> = ({ title, main, sidebar }) => {
  return (
    <div
      className={classNames({
        [s.wrapper]: true,
        [s.wrapperNoSidebar]: !sidebar,
      })}
    >
      <H2 className={s.title}>{title}</H2>
      <Card elevation={2} className={s.main}>
        {main}
      </Card>
      {sidebar ? <div className={s.sidebar}>{sidebar}</div> : null}
    </div>
  );
};

export default FormView;
