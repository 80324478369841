import { gql } from "@apollo/client";
import { LANGUAGE_ATTRIBUTES } from "./languages";

export const BOOK_ATTRIBUTES = gql`
  fragment BookAttributes on Book {
    id
    titleEn
    titlePl
    subtitleEn
    subtitlePl
    descriptionEn
    descriptionPl
    coverKey
    publisher
    pubYear
    published
  }
`;

export const MYBOOK_QUERY = gql`
  query MyBook($id: ID!) {
    myBook(id: $id) {
      ...BookAttributes
      authors {
        id
        name
      }
      transLangs {
        ...LanguageAttributes
      }
    }
  }
  ${BOOK_ATTRIBUTES}
  ${LANGUAGE_ATTRIBUTES}
`;

export const CREATE_BOOK_MUATATION = gql`
  mutation CreateBook($data: BookInput!) {
    createBook(data: $data) {
      ...BookAttributes
      authors {
        id
        name
      }
      transLangs {
        ...LanguageAttributes
      }
    }
  }
  ${BOOK_ATTRIBUTES}
  ${LANGUAGE_ATTRIBUTES}
`;

export const DELETE_BOOK_MUTATION = gql`
  mutation DeleteBook($id: ID!) {
    deleteBook(id: $id) {
      id
    }
  }
`;

export const UPDATE_BOOK_MUTATION = gql`
  mutation UpdateBook($id: ID!, $data: BookInput!) {
    updateBook(id: $id, data: $data) {
      ...BookAttributes
      authors {
        id
      }
      transLangs {
        ...LanguageAttributes
      }
    }
  }
  ${BOOK_ATTRIBUTES}
  ${LANGUAGE_ATTRIBUTES}
`;
