import React, { FC, ChangeEvent } from "react";
import { InputGroup, Button } from "@blueprintjs/core";
import s from "../stylesheets/Search.module.scss";

interface Props {
  query: string;
  placeholder?: string;
  reset: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Search: FC<Props> = ({ query, reset, onChange, placeholder }) => {
  return (
    <InputGroup
      large
      leftIcon="search"
      placeholder={placeholder ? placeholder : "Filter..."}
      onChange={onChange}
      value={query}
      className={s.search}
      rightElement={
        query.length > 0 ? (
          <Button icon="cross" minimal={true} onClick={() => reset()} />
        ) : undefined
      }
    />
  );
};

export default Search;
