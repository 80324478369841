import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { BACKEND_DOMAIN } from "./config/constants";

export const client = new ApolloClient({
  link: new HttpLink({
    uri: BACKEND_DOMAIN + "/query",
    credentials: "include",
  }),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          language(existingData, { args, toReference }) {
            if (existingData) return existingData;
            if (args)
              return toReference({ __typename: "Language", id: args.id });
          },
          team(existingData, { args, toReference }) {
            if (existingData) return existingData;
            if (args) return toReference({ __typename: "Team", id: args.id });
          },
          user(existingData, { args, toReference }) {
            if (existingData) return existingData;
            if (args) return toReference({ __typename: "User", id: args.id });
          },
        },
      },
    },
  }),
  // connectToDevTools: process.env.NODE_ENV !== "production",
});
