import { gql } from "@apollo/client";
import { USER_ATTRIBUTES } from "./users";

export const AUTHOR_ATTRIBUTES = gql`
  fragment AuthorAttributes on Author {
    id
    name
    slug
    photoListKey
    photoProfileKey
    bioEn
    bioPl
    published
  }
`;

export const AUTHORS_QUERY = gql`
  query Authors {
    authors {
      id
      name
    }
  }
`;

export const MYAUTHOR_QUERY = gql`
  query MyAuthor($id: ID!) {
    myAuthor(id: $id) {
      ...AuthorAttributes
      agent {
        ...UserAttributes
      }
      books {
        id
        titleEn
        titlePl
        coverKey
        pubYear
        published
        transLangs {
          id
        }
        authors {
          id
        }
      }
    }
  }
  ${AUTHOR_ATTRIBUTES}
  ${USER_ATTRIBUTES}
`;

export const MYAUTHORS_QUERY = gql`
  query MyAuthors {
    myAuthors {
      ...AuthorAttributes
      books {
        id
      }
    }
  }
  ${AUTHOR_ATTRIBUTES}
`;

export const CREATE_AUTHOR_MUTATION = gql`
  mutation CreateAuthor($data: AuthorInput!) {
    createAuthor(data: $data) {
      ...AuthorAttributes
      agent {
        id
      }
      books {
        id
      }
    }
  }
  ${AUTHOR_ATTRIBUTES}
`;

export const DELETE_AUTHOR_MUTATION = gql`
  mutation DeleteAuthor($id: ID!) {
    deleteAuthor(id: $id) {
      id
    }
  }
`;

export const UPDATE_AUTHOR_MUTATION = gql`
  mutation UpdateAuthor($id: ID!, $data: AuthorInput!) {
    updateAuthor(id: $id, data: $data) {
      ...AuthorAttributes
      agent {
        id
      }
      books {
        id
      }
    }
  }
  ${AUTHOR_ATTRIBUTES}
`;
