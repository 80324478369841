export const BUCKET_ROOT = "https://graal-assets.s3.amazonaws.com/";
export const AGENT_PHOTO_PREFIX = "media/public/agents/";
export const AUTHOR_LIST_PHOTO_PREFIX = "media/public/authors_list/";
export const AUTHOR_PROFILE_PHOTO_PREFIX = "media/public/authors_profile/";
export const BOOK_COVER_PREFIX = "media/public/book_covers/";
export const CATALOG_PREFIX = "media/private/catalogs/";
export const COVER_PLACEHOLDER =
  "https://d1jf7zda7gqvca.cloudfront.net/static/img/book_placeholder.png";
export const FRONTEND_DOMAIN =
  process.env.NODE_ENV === "production"
    ? "https://admin.graalagency.com"
    : "http://localhost:3000";
export const BACKEND_DOMAIN =
  process.env.NODE_ENV === "production"
    ? "https://graphql.graalagency.com"
    : "http://localhost:4000";
