import React, { FC } from "react";
import DeleteModal from "./DeleteModal";
import {
  DeleteTeam,
  DeleteTeamVariables,
  Teams_teams,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { DELETE_TEAM_MUTATION } from "../gql/teams";
import { deleteTeamUpdate } from "../updateFunctions/deleteTeamUpdate";

interface Props {
  deletedTeam: Teams_teams | null;
  redirectCallback?: () => void;
  handleClose: () => void;
}

const DelTeam: FC<Props> = ({ deletedTeam, handleClose, redirectCallback }) => {
  const deleteCatalog = useCustomMutation<DeleteTeam, DeleteTeamVariables>({
    mutation: DELETE_TEAM_MUTATION,
    successMsg: "Team deleted successfully",
    redirectCallback: redirectCallback ? redirectCallback : handleClose,
  });
  return deletedTeam ? (
    <DeleteModal
      deletedItem={deletedTeam}
      nameFn={(item) => item.agent.profile.name}
      handleClose={handleClose}
      handleRedirect={redirectCallback}
      handleDelete={(id) =>
        deleteCatalog({
          variables: { id },
          update: deleteTeamUpdate,
        })
      }
    />
  ) : null;
};

export default DelTeam;
