import React, { FC } from "react";
import {
  DeleteBook,
  DeleteBookVariables,
  MyAuthor_myAuthor_books,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { DELETE_BOOK_MUTATION } from "../gql/books";
import DeleteModal from "./DeleteModal";
import { deleteBookUpdate } from "../updateFunctions/deleteBookUpdate";

interface Props {
  redirectCallback?: () => void;
  deletedBook: MyAuthor_myAuthor_books | null;
  handleClose: () => void;
}

const DelBook: FC<Props> = ({ deletedBook, handleClose, redirectCallback }) => {
  const deleteBook = useCustomMutation<DeleteBook, DeleteBookVariables>({
    mutation: DELETE_BOOK_MUTATION,
    successMsg: "Book deleted successfully",
    redirectCallback: redirectCallback ? redirectCallback : handleClose,
  });
  return deletedBook ? (
    <DeleteModal
      deletedItem={deletedBook}
      nameFn={(item) => item.titleEn}
      handleClose={handleClose}
      handleRedirect={redirectCallback}
      handleDelete={(id) =>
        deleteBook({
          variables: { id },
          update: deleteBookUpdate(deletedBook.authors.map((a) => a.id)),
        })
      }
    />
  ) : null;
};

export default DelBook;
