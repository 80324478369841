import { ChangeEvent, useState } from "react";

export const useTextFilter = (): [
  string,
  () => void,
  (event: ChangeEvent<HTMLInputElement>) => void
] => {
  const [query, setQuery] = useState("");
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setQuery(
      event.currentTarget.value.replace(/[^A-Za-z\u00C0-\u017F\s]/g, "")
    );
  const resetQuery = (): void => setQuery("");
  return [query, resetQuery, handleOnChange];
};
