import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { CreateUser, CreateUserVariables } from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { CREATE_USER_MUTATION } from "../gql/users";
import UserForm from "./UserForm";
import FormView from "./FormView";
import { createUserUpdate } from "../updateFunctions/createUserUpdate";

const UserNew: FC<RouteComponentProps> = ({ history: { push } }) => {
  const createUser = useCustomMutation<CreateUser, CreateUserVariables>({
    mutation: CREATE_USER_MUTATION,
    successMsg: "User created successfully",
    redirectCallback: () => push("/users"),
  });

  const initialValues: UserValues = {
    email: "",
    admin: false,
    name: "",
  };

  return (
    <FormView
      title="New User"
      main={
        <UserForm
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await createUser({
                variables: { data: values },
                update: createUserUpdate,
              });
            } catch (error) {
              setSubmitting(false);
            }
          }}
          onBackClick={() => push("/users")}
        />
      }
    />
  );
};

export default UserNew;
