import React, { FC, Dispatch, createContext, useReducer } from "react";
import { Me } from "../__generated__/types";
import { useApolloClient } from "@apollo/client";

interface State {
  status: "LOGGED_IN" | "LOGGED_OUT" | "LOGGING_OUT";
  user?: Me;
}
interface LoginAction {
  type: "LOGIN";
  payload: Me;
}
interface LogoutAction {
  type: "LOGOUT";
}
interface ProfileUpdateAction {
  type: "PROFILE_UPDATE";
  payload: {
    name: string;
    photo: string;
  };
}
type Actions = LoginAction | LogoutAction | ProfileUpdateAction;
type ContextProps = [State, Dispatch<Actions>];

const initialState: State = {
  status: "LOGGED_OUT",
  user: undefined,
};

export const LoginContext = createContext<ContextProps>([
  initialState,
  () => null,
]);

export const LoginProvider: FC = ({ children }) => {
  const client = useApolloClient();
  const loginReducer = (state: State, action: Actions): State => {
    switch (action.type) {
      case "LOGIN":
        return { ...state, status: "LOGGED_IN", user: action.payload };
      case "LOGOUT":
        client.clearStore();
        return { ...state, status: "LOGGING_OUT", user: undefined };
      case "PROFILE_UPDATE":
        return {
          status: state.status,
          user: state.user
            ? {
                me: {
                  ...state.user.me,
                  profile: {
                    ...state.user.me.profile,
                    name: action.payload.name,
                    photo: action.payload.photo,
                  },
                },
              }
            : undefined,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(loginReducer, initialState);
  return (
    <LoginContext.Provider value={[state, dispatch]}>
      {children}
    </LoginContext.Provider>
  );
};
