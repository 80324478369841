import { BACKEND_DOMAIN, FRONTEND_DOMAIN } from "../config/constants";
import { useHistory } from "react-router";

export function useLoginLogout() {
  const { location } = useHistory();

  function login() {
    window.location.assign(
      BACKEND_DOMAIN +
        "/login?redirect=" +
        encodeURI(FRONTEND_DOMAIN + location.pathname)
    );
  }

  function logout() {
    window.location.assign(BACKEND_DOMAIN + "/logout");
  }

  return { login, logout };
}
