import React, { FC } from "react";
import {
  DeleteLanguage,
  DeleteLanguageVariables,
  Languages_languages,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { DELETE_LANGUAGE_MUTATION } from "../gql/languages";
import DeleteModal from "./DeleteModal";
import { deleteLanguageUpdate } from "../updateFunctions/deleteLanguageUpdate";

interface Props {
  redirectCallback?: () => void;
  deletedLanguage: Languages_languages | null;
  handleClose: () => void;
}

const DelLanguage: FC<Props> = ({
  deletedLanguage,
  handleClose,
  redirectCallback,
}) => {
  const deleteLanguage = useCustomMutation<
    DeleteLanguage,
    DeleteLanguageVariables
  >({
    mutation: DELETE_LANGUAGE_MUTATION,
    successMsg: "Language deleted successfully",
    redirectCallback: redirectCallback ? redirectCallback : handleClose,
  });
  return deletedLanguage ? (
    <DeleteModal
      deletedItem={deletedLanguage}
      nameFn={(item) => item.nameEn}
      handleClose={handleClose}
      handleRedirect={redirectCallback}
      handleDelete={(id) =>
        deleteLanguage({
          variables: { id },
          update: deleteLanguageUpdate,
        })
      }
    />
  ) : null;
};

export default DelLanguage;
