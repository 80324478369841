import { MutationUpdaterFn } from "@apollo/client";
import { CreateTerritory, Territories } from "../__generated__/types";
import { updateCache } from "../utils/updateCache";
import { TERRITORIES_QUERY } from "../gql/territories";

export const createTerritoryUpdate: MutationUpdaterFn<CreateTerritory> = (
  cache,
  result
) => {
  updateCache<Territories, {}, CreateTerritory>({
    cache,
    result,
    cachedQuery: { query: TERRITORIES_QUERY },
    update: ({ territories }, { createTerritory }) => ({
      territories: territories.concat([createTerritory]),
    }),
  });
};
