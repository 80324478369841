import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useCustomQuery } from "../hooks/useCustomQuery";
import { Territories } from "../__generated__/types";
import { TERRITORIES_QUERY } from "../gql/territories";
import ErrorPage from "./ErrorPage";
import Loading from "./Loading";
import ListView from "./ListView";
import TerritoryCard from "./TerritoryCard";

const TerritoryList: React.FC<RouteComponentProps> = ({ history }) => {
  const { errorCode, loading, data } = useCustomQuery<Territories>({
    query: TERRITORIES_QUERY,
  });

  if (loading) {
    return <Loading />;
  }

  if (data) {
    return (
      <ListView
        title="Territories"
        entities={data.territories}
        filterFn={({ nameEn, namePl }, query) =>
          nameEn.toLowerCase().indexOf(query) !== -1 ||
          namePl.toLowerCase().indexOf(query) !== -1
        }
        sortFn={(a, b) => Intl.Collator().compare(a.nameEn, b.nameEn)}
        onNewClick={() => history.push("/territories/new")}
      >
        {({ entities }) =>
          entities.map((e) => (
            <TerritoryCard key={e.id} territory={e} history={history} />
          ))
        }
      </ListView>
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default TerritoryList;
