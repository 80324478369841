import { MutationUpdaterFn } from "@apollo/client";
import { DeleteUser, Users, MyAgents, Teams } from "../__generated__/types";
import { updateCache } from "../utils/updateCache";
import { USERS_QUERY, MYAGENTS_QUERY } from "../gql/users";
import { TEAMS_QUERY } from "../gql/teams";

export const deleteUserUpdate: MutationUpdaterFn<DeleteUser> = (
  cache,
  result
) => {
  // if (result.data?.deleteUser) {
  //   const { id, __typename } = result.data.deleteUser;
  //   cache.evict(`Profile:${profileID}`);
  //   cache.evict(`${__typename}:${id}`);
  //   cache.gc();
  // }

  updateCache<Users, {}, DeleteUser>({
    cache,
    result,
    cachedQuery: { query: USERS_QUERY },
    update: ({ users }, { deleteUser: { id } }) => ({
      users: users.filter((u) => u.id !== id),
    }),
  });

  updateCache<MyAgents, {}, DeleteUser>({
    cache,
    result,
    cachedQuery: { query: MYAGENTS_QUERY },
    update: ({ myAgents }, { deleteUser: { id } }) => ({
      myAgents: myAgents.filter((u) => u.id !== id),
    }),
  });

  updateCache<Teams, {}, DeleteUser>({
    cache,
    result,
    cachedQuery: { query: TEAMS_QUERY },
    update: ({ teams }, { deleteUser: { id } }) => ({
      teams: teams
        .filter((team) => team.agent.id !== id)
        .map((team) =>
          team.assistants?.some((a) => a.id === id)
            ? {
                ...team,
                assistants: team.assistants.filter((a) => a.id !== id),
              }
            : team
        ),
    }),
  });
};
