import { MutationUpdaterFn } from "@apollo/client";
import { MYCLIENTS_QUERY, MYCLIENT_QUERY } from "../gql/clients";
import { updateCache } from "../utils/updateCache";
import {
  DeleteCatalog,
  MyClient,
  MyClients,
  MyClientVariables,
} from "../__generated__/types";

export const deleteCatalogUpdate = (
  clientID: string
): MutationUpdaterFn<DeleteCatalog> => (cache, result) => {
  // MYCLIENTS_QUERY
  updateCache<MyClients, unknown, DeleteCatalog>({
    cache,
    result,
    cachedQuery: { query: MYCLIENTS_QUERY },
    update: ({ myClients }, { deleteCatalog: { id } }) => ({
      myClients: myClients.map((client) =>
        client.id === clientID
          ? {
              ...client,
              catalogs: client.catalogs.filter((c) => c.id !== id),
            }
          : client
      ),
    }),
  });

  // MYCLIENT_QUERY
  updateCache<MyClient, MyClientVariables, DeleteCatalog>({
    cache,
    result,
    cachedQuery: {
      query: MYCLIENT_QUERY,
      variables: { id: clientID },
    },
    update: ({ myClient }, { deleteCatalog: { id } }) => ({
      myClient: {
        ...myClient,
        catalogs: myClient.catalogs.filter((c) => c.id !== id),
      },
    }),
  });

  if (result.data?.deleteCatalog) {
    const { id, __typename } = result.data.deleteCatalog;
    cache.evict({ id: `${__typename}:${id}` });
    cache.gc();
  }
};
