import React, { FC, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { FormikHelpers, Formik, Form } from "formik";
import classNames from "classnames";
import { AGENT_PHOTO_PREFIX } from "../config/constants";
import CheckboxField from "./CheckboxField";
import FileInputField from "./FileInputField";
import ImgPreview from "./ImgPreview";
import TextAreaField from "./TextAreaField";
import TextField from "./TextField";
import s from "../stylesheets/UserProfileForm.module.scss";
import FormButtons from "./FormButtons";

interface Props extends RouteComponentProps {
  initialValues: UserProfileValues;
  onSubmit: (
    values: UserProfileValues,
    formikHelpers: FormikHelpers<UserProfileValues>
  ) => void;
}

const UserProfileForm: FC<Props> = ({ initialValues, onSubmit, history }) => {
  const [isUploading, setIsUploading] = useState(false);
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, isSubmitting }) => (
        <Form autoComplete="off">
          <div className={classNames(s.row, s.bio)}>
            <TextField
              name="name"
              label="Name"
              helperText="Your name, as you'd like it to be displayed on the agents page."
            />
            <TextAreaField
              name="bioEn"
              label="English Bio"
              helperText="Biographical note, in English"
              spellCheck="true"
              lang="en"
            />
          </div>
          <div className={classNames(s.row, s.bio)}>
            <TextAreaField
              name="bioPl"
              label="Polish Bio"
              helperText="Biographical note, in Polish"
              spellCheck="true"
              lang="pl"
            />
          </div>
          <div className={classNames(s.row, s.photo)}>
            <FileInputField
              name="photoKey"
              label="Photo"
              fileType="image/*"
              helperText="Choose and upload an image file"
              uploadPrefix={AGENT_PHOTO_PREFIX}
              setUploading={setIsUploading}
              acl="public-read"
              preview={
                <ImgPreview
                  imgKey={values.photoKey}
                  width={150}
                  height={values.positionEn === "Owner" ? 202 : 168}
                />
              }
            />
          </div>
          <div className={classNames(s.row, s.position)}>
            <TextField
              name="positionEn"
              label="Position in English"
              helperText="Title or position in the agency, in English"
            />
          </div>
          <div className={classNames(s.row, s.position)}>
            <TextField
              name="positionPl"
              label="Position in Polish"
              helperText="Title or position in the agency, in Polish"
            />
          </div>
          <div>
            <CheckboxField name="published" label="Published" />
          </div>
          <FormButtons
            onBackClick={() => history.goBack()}
            submitDisabled={isSubmitting || isUploading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(UserProfileForm);
