import sanitize from "sanitize-filename";
import { makeID } from "./makeID";

export function safeUnique(filename: string) {
  const dotIndex = filename.lastIndexOf(".");
  const random = makeID();
  if (dotIndex === -1) return sanitize(filename + random);
  return sanitize(
    filename.substring(0, dotIndex) +
      "_" +
      random +
      filename.substring(dotIndex)
  );
}
