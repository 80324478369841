import React, { FC, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import classNames from "classnames";
import { FormikHelpers, Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Territories_territories,
  MailchimpTerritories,
} from "../__generated__/types";
import TextField from "./TextField";
import FormButtons from "./FormButtons";
import DeleteTerritory from "./DeleteTerritory";
import s from "../stylesheets/TerritoryForm.module.scss";
import SelectField from "./SelectField";
import { useCustomQuery } from "../hooks/useCustomQuery";
import { MAILCHIMP_TERRITORIES_QUERY } from "../gql/territories";
import Loading from "./Loading";
import ErrorPage from "./ErrorPage";

interface Props extends RouteComponentProps {
  initialValues: TerritoryValues;
  onBackClick: () => void;
  onSubmit: (
    values: TerritoryValues,
    formikHelpers: FormikHelpers<TerritoryValues>
  ) => void;
  prevTerritory?: Territories_territories | null;
}

const requiredMsg = "Required";

const validationSchema = Yup.object().shape({
  iso3: Yup.string()
    .max(3, "Must be exactly 3 lettes long")
    .required(requiredMsg),
  nameEn: Yup.string().required(requiredMsg),
  namePl: Yup.string().required(requiredMsg),
  languageEn: Yup.string().required(requiredMsg),
  languagePl: Yup.string().required(requiredMsg),
  mailchimpID: Yup.string().required(requiredMsg),
});

const TerritoryForm: FC<Props> = ({
  history: { push },
  initialValues,
  onBackClick,
  onSubmit,
  prevTerritory,
}) => {
  const [delLang, setDelLang] = useState<Territories_territories | null>(null);
  const { data, errorCode, loading } = useCustomQuery<MailchimpTerritories>({
    query: MAILCHIMP_TERRITORIES_QUERY,
  });

  if (loading) {
    return <Loading />;
  }

  if (data && data.mailchimpTerritories) {
    const { mailchimpTerritories } = data;
    return (
      <Formik<TerritoryValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <div className={classNames(s.row, s.name)}>
              <TextField
                name="iso3"
                label="ISO3 code"
                helperText="ISO3 code of the territory (i.e. POL for Poland)"
              />
            </div>
            <div className={classNames(s.row, s.name)}>
              <TextField
                name="nameEn"
                label="English name"
                helperText="Name of the territory in English"
              />
            </div>
            <div className={classNames(s.row, s.name)}>
              <TextField
                name="namePl"
                label="Polish name"
                helperText="Name of the territory in Polish"
              />
            </div>
            <div className={classNames(s.row, s.name)}>
              <TextField
                name="languageEn"
                label="English name of the language"
                helperText="Name of the territory's language in English"
              />
            </div>
            <div className={classNames(s.row, s.name)}>
              <TextField
                name="languagePl"
                label="Polish name of the language"
                helperText="Name of the territory's language in Polish"
              />
            </div>
            <div className={s.row}>
              <SelectField
                name="mailchimpID"
                label="Mailchimp ID"
                helperText="Mailchimp territory group ID (created using Mailchimp UI)"
                items={mailchimpTerritories.map((mct) => ({
                  id: mct.id,
                  display: `${mct.name} (${mct.id})`,
                }))}
              />
            </div>
            <FormButtons
              onBackClick={onBackClick}
              onDeleteClick={
                prevTerritory ? () => setDelLang(prevTerritory) : undefined
              }
              submitDisabled={isSubmitting}
            />
            {prevTerritory && (
              <DeleteTerritory
                deletedTerritory={delLang}
                handleClose={() => setDelLang(null)}
                redirectCallback={() => push(`/territories`)}
              />
            )}
          </Form>
        )}
      </Formik>
    );
  }
  return <ErrorPage code={errorCode || 500} />;
};

export default withRouter(TerritoryForm);
