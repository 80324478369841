import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import { CreateClient, CreateClientVariables } from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { CREATE_CLIENT_MUTATION } from "../gql/clients";
import ClientForm from "./ClientForm";
import FormView from "./FormView";
import { createClientUpdate } from "../updateFunctions/createClientUpdate";

const ClientNew: FC<RouteComponentProps> = ({ history }) => {
  const createClient = useCustomMutation<CreateClient, CreateClientVariables>({
    mutation: CREATE_CLIENT_MUTATION,
    successMsg: "Client created successfully",
    redirectCallback: () => history.push("/clients"),
  });

  const initialValues: ClientValues = {
    name: "",
    descriptionEn: "",
    descriptionPl: "",
    letters: "",
    website: "",
    published: true,
    agentID: "",
    territoryIDs: [],
  };

  return (
    <FormView
      title="New Client"
      main={
        <ClientForm
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await createClient({
                variables: { data: values },
                update: createClientUpdate,
              });
            } catch (error) {
              setSubmitting(false);
            }
          }}
        />
      }
    />
  );
};

export default ClientNew;
