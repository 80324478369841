import React, { FC } from "react";
import { Icon } from "@blueprintjs/core";
import s from "../stylesheets/ImgPlaceholder.module.scss";

interface Props {
  width?: number;
  height?: number;
}

const ImgPlaceholder: FC<Props> = ({ width = 200, height = 150 }) => {
  return (
    <div style={{ width, height }} className={s.root}>
      <Icon icon="media" iconSize={48} className={s.icon} />
    </div>
  );
};

export default ImgPlaceholder;
