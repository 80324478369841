import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useCustomQuery } from "../hooks/useCustomQuery";
import { Users } from "../__generated__/types";
import { USERS_QUERY } from "../gql/users";
import ErrorPage from "./ErrorPage";
import Loading from "./Loading";
import ListView from "./ListView";
import UserCard from "./UserCard";

const UserList: React.FC<RouteComponentProps> = ({ history }) => {
  const { errorCode, loading, data } = useCustomQuery<Users>({
    query: USERS_QUERY,
  });

  if (loading) {
    return <Loading />;
  }

  if (data?.users) {
    return (
      <ListView
        title="Users"
        entities={data.users}
        filterFn={(item, query) =>
          item.profile.name.toLowerCase().indexOf(query) !== -1
        }
        sortFn={(a, b) =>
          Intl.Collator().compare(a.profile.name, b.profile.name)
        }
        onNewClick={() => history.push("/users/new")}
      >
        {({ entities }) =>
          entities.map((e) => (
            <UserCard key={e.id} user={e} history={history} />
          ))
        }
      </ListView>
    );
  }

  return <ErrorPage code={errorCode || 500} />;
};

export default UserList;
