import React, { SyntheticEvent } from "react";
import { useField } from "formik";
import { MenuItem, FormGroup, Intent, Button } from "@blueprintjs/core";
import { MultiSelect, IItemRendererProps } from "@blueprintjs/select";

interface Props {
  name: string;
  label: string;
  helperText: string;
  items: Option[];
  excludeID?: string;
}

const MultiSelectField: React.FC<Props> = ({
  label,
  helperText,
  items,
  excludeID,
  ...props
}) => {
  const [{ value }, { touched, error }, { setValue }] = useField<string[]>(
    props
  );

  const TypedMultiSelect = MultiSelect.ofType<Option>();

  // determines whether an item is one of the currently selected values
  function isSelected(item: Option): boolean {
    return value.indexOf(item.id) !== -1;
  }

  // behavior on selecting an item
  function onSelectItem(item: Option, e?: SyntheticEvent<HTMLElement>) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (isSelected(item)) {
      setValue([...value.filter((id) => id !== item.id)]);
    } else {
      setValue([...value, item.id]);
    }
  }

  // behavior on removing item by clickin on the close button on the tag
  function onTagRemove(_tag: string, index: number) {
    setValue(value.filter((id) => id !== value[index]));
  }

  function itemListPredicate(query: string, items: Option[]) {
    return items.filter(
      (item) => item.display.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  function itemRenderer(item: Option, props: IItemRendererProps) {
    const { modifiers, handleClick } = props;
    return modifiers.matchesPredicate ? (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        icon={isSelected(item) ? "tick" : "blank"}
        onClick={handleClick}
        text={item.display}
        shouldDismissPopover={false}
      />
    ) : null;
  }

  const itemMap = items.reduce<{ [id: string]: Option }>((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});

  const errorMsg = touched && error;

  const excluded = React.useRef("");
  React.useEffect(() => {
    if (excludeID && excludeID !== excluded.current) {
      excluded.current = excludeID;
      setValue(value.filter((v) => v !== excludeID));
    }
  }, [excludeID, value, setValue]);

  return (
    <FormGroup
      label={label}
      helperText={errorMsg ? errorMsg : helperText}
      intent={errorMsg ? Intent.DANGER : Intent.NONE}
    >
      <TypedMultiSelect
        tagRenderer={(item) => item.display}
        items={excludeID ? items.filter((i) => i.id !== excludeID) : items}
        itemsEqual="id"
        itemListPredicate={itemListPredicate}
        itemRenderer={itemRenderer}
        onItemSelect={onSelectItem}
        selectedItems={value.map((id) => itemMap[id])}
        noResults={<MenuItem disabled={true} text="No results." />}
        popoverProps={{ minimal: true }}
        tagInputProps={{
          tagProps: { minimal: true },
          onRemove: onTagRemove,
          rightElement: (
            <ClearButton clearFn={() => setValue([])} show={value.length > 0} />
          ),
          large: true,
          onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => {
            if (e.keyCode === 13) {
              e.preventDefault();
            }
          },
        }}
        resetOnSelect
      />
    </FormGroup>
  );
};

interface ClearButtonProps {
  show: boolean;
  clearFn: () => void;
}

const ClearButton: React.FC<ClearButtonProps> = ({ show, clearFn }) => {
  return show ? (
    <Button type="button" icon="cross" minimal={true} onClick={clearFn} />
  ) : null;
};

export default MultiSelectField;
