import React, { FC } from "react";
import { RouteComponentProps } from "react-router";
import {
  CreateCatalog,
  CreateCatalogVariables,
  MyClients,
  MyClient,
  MyClientVariables,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { MYCLIENT_QUERY } from "../gql/clients";
import { MYCLIENTS_QUERY } from "../gql/clients";
import { CREATE_CATALOG_MUTATION } from "../gql/catalogs";
import CatalogForm from "./CatalogForm";
import FormView from "./FormView";
import { updateCache } from "../utils/updateCache";

const CatalogNew: FC<RouteComponentProps<
  {},
  {},
  { clientID?: string } | undefined
>> = ({ history, location: { state } }) => {
  // if the user came from client edit screen redirect back there, else to clients list
  const redirectURL = state?.clientID
    ? `/clients/${state.clientID}/edit`
    : "/clients";

  const createCatalog = useCustomMutation<
    CreateCatalog,
    CreateCatalogVariables
  >({
    mutation: CREATE_CATALOG_MUTATION,
    successMsg: "Catalog created successfully",
    redirectCallback: () => history.push(redirectURL),
  });

  return (
    <FormView
      title="New Catalog"
      main={
        <CatalogForm
          initialValues={{
            name: "",
            url: "",
            fileKey: "",
            dateAdded: new Date(),
            clientID: state && state.clientID ? state.clientID : "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await createCatalog({
                variables: { data: values },
                update: (cache, result) => {
                  updateCache<MyClients, {}, CreateCatalog>({
                    cache,
                    result,
                    cachedQuery: { query: MYCLIENTS_QUERY },
                    update: ({ myClients }, { createCatalog }) => ({
                      myClients: myClients.map((client) =>
                        client.id === createCatalog.client.id
                          ? {
                              ...client,
                              catalogs: client.catalogs.concat([
                                {
                                  id: createCatalog.id,
                                  __typename: createCatalog.__typename,
                                  dateAdded: createCatalog.dateAdded,
                                },
                              ]),
                            }
                          : client
                      ),
                    }),
                  });
                  updateCache<MyClient, MyClientVariables, CreateCatalog>({
                    cache,
                    result,
                    cachedQuery: {
                      query: MYCLIENT_QUERY,
                      variables: { id: values.clientID },
                    },
                    update: (
                      { myClient },
                      {
                        createCatalog: {
                          id,
                          __typename,
                          dateAdded,
                          fileKey,
                          link,
                          name,
                          url,
                        },
                      }
                    ) => {
                      // if the catalog is already listed do nothing...
                      if (myClient.catalogs.some((c) => c.id === id)) {
                        return { myClient };
                      }
                      // ...otherwise add the catalog to the list
                      return {
                        myClient: {
                          ...myClient,
                          catalogs: myClient.catalogs.concat([
                            {
                              id,
                              __typename,
                              dateAdded,
                              fileKey,
                              link,
                              name,
                              url,
                            },
                          ]),
                        },
                      };
                    },
                  });
                },
              });
            } catch (error) {
              setSubmitting(false);
            }
          }}
          onBackClick={() => history.push(redirectURL)}
        />
      }
    />
  );
};

export default CatalogNew;
