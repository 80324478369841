import { MutationUpdaterFn } from "@apollo/client";
import { DeleteAuthor } from "../__generated__/types";

export const deleteAuthorUpdate: MutationUpdaterFn<DeleteAuthor> = (
  cache,
  result
) => {
  if (result.data?.deleteAuthor) {
    const { id, __typename } = result.data.deleteAuthor;
    cache.evict({ id: `${__typename}:${id}` });
    cache.gc();
  }
};
