import React, { FC } from "react";
import {
  DeleteUser,
  DeleteUserVariables,
  Users_users,
} from "../__generated__/types";
import { useCustomMutation } from "../hooks/useCustomMutation";
import { DELETE_USER_MUTATION } from "../gql/users";
import DeleteModal from "./DeleteModal";
import { deleteUserUpdate } from "../updateFunctions/deleteUserUpdate";

interface Props {
  redirectCallback?: () => void;
  deletedUser: Users_users | null;
  handleClose: () => void;
}

const DelLanguage: FC<Props> = ({
  deletedUser,
  handleClose,
  redirectCallback,
}) => {
  const deleteUser = useCustomMutation<DeleteUser, DeleteUserVariables>({
    mutation: DELETE_USER_MUTATION,
    successMsg: "User deleted successfully",
    redirectCallback: redirectCallback ? redirectCallback : handleClose,
  });
  return deletedUser ? (
    <DeleteModal
      deletedItem={deletedUser}
      nameFn={(item) => item.profile.name}
      handleClose={handleClose}
      handleRedirect={redirectCallback}
      handleDelete={(id) =>
        deleteUser({
          variables: { id },
          update: deleteUserUpdate,
        })
      }
    />
  ) : null;
};

export default DelLanguage;
